import React, { RefObject, useRef } from 'react'
import ReactHlsPlayer from 'react-hls-video-player'

import { getStateNameFromUrl } from '../../../utils'
// import { getArticle } from '../../../components/AddArticle'
import MobileVideoCallHeader from '../../../layouts/Header/MobileVideoCallHeader'
import MobileVideoHeader from '../../../layouts/Header/MobileVideoHeader'
import DesktopVideoCallHeader from '../../../layouts/Header/DesktopVideoCallHeader'
import DesktopVideoHeader from '../../../layouts/Header/DesktopVideoHeader'

interface BannerProps {
    connect_bool: boolean
    call_header?: boolean
    phone?: string
    display_phone?: string
    law_category?: string
    law_area?: string
    video?: string
    img?: string
    location?: string
    headline?: string
}

const Banner: React.FC<BannerProps> = ({
    //connect_bool,
    call_header,
    phone,
    display_phone,
    //law_category,
    //law_area,
    video,
    img,
    //location,
    headline,
}) => {
    const mainPlayerRef = useRef<HTMLVideoElement>(null) as RefObject<HTMLVideoElement>
    const hlsConfig = {
        capLevelToPlayerSize: true,
        capLevelOnFPSDrop: true,
        startLevel: -1,
        minAutoBitrate: 800000,
    }
    //     window.addEventListener('scroll', handleScroll)
    //     return () => window.removeEventListener('scroll', handleScroll)
    // }, [])
    // const parseLawArea = (str?: string) => {
    //     if (str === undefined) return ''
    //
    //     let result = ''
    //     const split = str.split('-')
    //
    //     for (let i = 0; i < split.length; i++) {
    //         if (split[i].length > 0) {
    //             result +=
    //                 split[i].charAt(0).toUpperCase() +
    //                 split[i].substring(1).toLowerCase() +
    //                 ' '
    //         }
    //     }
    //
    //     return result
    // }

    // const parseLocation = (str?: string) => {
    //     if (str === undefined) return ''
    //
    //     let result = ''
    //     const split = str.split('-')
    //
    //     for (let i = 0; i < split.length - 1; i++) {
    //         if (split[i].length > 0) {
    //             result +=
    //                 split[i].charAt(0).toUpperCase() +
    //                 split[i].substring(1).toLowerCase() +
    //                 ' '
    //         }
    //     }
    //
    //     if (split[split.length - 1].length === 2) {
    //         result =
    //             result.substring(0, result.length - 1) +
    //             ', ' +
    //             split[split.length - 1].toUpperCase()
    //     } else if (split[split.length - 1].length > 0) {
    //         result +=
    //             split[split.length - 1].charAt(0).toUpperCase() +
    //             split[split.length - 1].substring(1).toLowerCase()
    //     }
    //
    //     return result
    // }

    //const parsed_law_area = parseLawArea(law_area)
    //const parsed_location = parseLocation(location)
    return (
        <>
            {call_header ? <MobileVideoCallHeader phone={phone!} /> : <MobileVideoHeader />}
            {/* <div className=" sticky top-0 z-[1000] flex w-full items-center justify-between bg-white p-[16px] shadow-2xl md:hidden">

                <Link
                    className="ml-0 md:ml-[50px] md:rounded-b-[16px] md:px-[20px] md:py-[16px] lg:ml-[80px]"
                    to="/"
                >
                    <StateLogo className="h-[36px]" />
                </Link>

                <button
                    onClick={handleCall}
                    className="mr-6 rounded-[8px] bg-SecondColor p-2 text-[19px] font-extrabold text-white shadow-[0px_4px_0px_0px] shadow-ButtonShadow"
                >
                    Call Now
                </button>
            </div> */}
            <div
                className=" relative mx-auto h-[500px] w-auto max-w-[1920px] overflow-hidden bg-cover md:h-[580px]"
                style={{ backgroundImage: `url(${img})` }}
            >
                <ReactHlsPlayer
                    id="videoElement"
                    playerRef={mainPlayerRef}
                    src={video ?? ''}
                    className={`-z-1 absolute left-1/2 top-1/2 h-full w-auto -translate-x-1/2 -translate-y-[50%] transform object-cover lg:h-auto lg:w-full  xl:-translate-y-[40%]`}
                    autoPlay={true}
                    loop
                    muted
                    playsInline
                    preload="auto"
                    hlsConfig={hlsConfig}
                />
                <div className="overlay absolute z-[0] h-full w-full bg-[#00000094]"></div>
                <div className="absolute  z-[1] h-full  w-full  items-center justify-center">
                    {call_header ? (
                        <DesktopVideoCallHeader phone={phone!} display_phone={display_phone!} />
                    ) : (
                        <DesktopVideoHeader />
                    )}

                    <div className="my-auto flex h-full justify-center ">
                        <div className="mx-auto my-auto max-w-[1280px] px-4 md:px-5">
                            <div className="items-center justify-center md:px-[20px]">
                                {headline && (
                                    <div className=" font-Leitura mx-auto mb-[40px] flex items-center justify-center md:mb-[60px]">
                                        <p className="rounded-full bg-BgColor px-[12px] pb-[4px] pt-[5px] text-center text-MainColor sm:text-[14px] md:px-[16px] md:pb-[7px] md:pt-[10px] md:text-[18px] lg:text-[24px]">
                                            {headline}
                                        </p>
                                    </div>
                                )}
                                {/* <div className="justify-center text-[26px] md:text-[36px] lg:flex">
                                    <div className="text-center  leading-tight text-Neutral000">
                                        Connect with
                                        {' ' + getArticle(parsed_law_area)}
                                    </div>
                                </div> */}
                                {/*<div className="flex justify-center ">
                                    <h1 className="font-Leitura text-center text-[36px] leading-tight text-Neutral000 md:text-[48px] lg:text-[60px]">
                                        {parsed_law_area
                                            ? parsed_law_area
                                            : law_category + ' Lawyer'}{' '}
                                        in
                                    </h1>
                                </div>*/}
                                <div className="flex justify-center ">
                                    <h1 className="font-Leitura text-center text-[36px] leading-tight text-Neutral000 md:text-[48px] lg:text-[60px]">
                                        {getStateNameFromUrl(window.location.hostname)}
                                        's Trusted Injury <br />
                                        Law Group{' '}
                                    </h1>
                                </div>

                                {/* {parsed_location !== '' && ( */}
                                {/*<div className="-mt-[5px] flex justify-center ">
                                    <div className=" font-Leitura  text-center  text-[36px] leading-tight text-Neutral000 md:text-[48px] lg:text-[60px]">
                                        <span className="">
                                            {parsed_location
                                                ? parsed_location
                                                : 'Your Area'}
                                        </span>
                                    </div>
                                </div>*/}
                                {/* )} */}
                                {/* <a href="#form">
                                <ButtonCTA className='flex w-full  justify-center'/>
                                   
                                </a> */}

                                {/* <ul className="flex flex-col pt-[10px] text-[16px] md:text-[17px] font-medium items-start sm:items-center sm:flex-row sm:flex-wrap sm:justify-center space-y-3 md:space-y-0 sm:space-x-8">
                                <li className="flex">
                                    <img
                                        src={ShieldIcon}
                                        className="w-[17px] md:w-[24px] mr-[5px]"
                                    />
                                    <span className="">
                                        Get Started For As Low As $500 to $1,500
                                    </span>
                                </li>
                                <li className="flex">
                                    <img
                                        src={ShieldIcon}
                                        className="w-[17px] md:w-[24px] mr-[5px]"
                                    />
                                    <span>
                                        Affordable Payment Plans Available
                                    </span>
                                </li>
                                <li className="flex">
                                    <img
                                        src={ShieldIcon}
                                        className="w-[17px] md:w-[24px] mr-[5px]"
                                    />
                                    <span>
                                        10+ Years Serving Hawaii Community
                                    </span>
                                </li>
                            </ul> */}
                                {/* <div className="pt-[20px] font-Arial">
                                <div className=" flex pt-[20px] pb-[10px]">
                                    <button
                                        onClick={updateHide}
                                        className="mx-auto w-full md:w-[370px] mb-[15px] text-[18px] md:text-[22px] font-bold h-[68px] bg-SecondColor text-Neutral000 rounded-lg focus-visible:outline-none focus:outline"
                                    >
                                        Give Me A Free Consultation
                                    </button>
                                </div>
                                <div className="flex">
                                    <div className="mx-auto text-Neutral000 text-center text-[18px]">
                                        We Instantly Connect you with
                                        {law_category
                                            ? addArticle(law_category)
                                            : 'a'}
                                        Lawyer for a
                                        <span className="font-bold">
                                            Free Review
                                        </span>
                                    </div>
                                </div>
                            </div> */}
                            </div>
                        </div>

                        {/* <div>
                    <img src={Lawyer} className="w-[650px]" />
                </div> */}
                    </div>
                </div>
                <div className="bouncing-arrow z-[999] mt-[450px] md:mt-[525px]">
                    {/* <p className="text-sm">Scroll Down</p> */}
                    <div className="arrow flex flex-col items-center justify-center">
                        <svg
                            className="h-8 w-8 text-white"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svga"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="3"
                                d="M19 9l-7 7-7-7"
                            ></path>
                        </svg>
                        <svg
                            className="mt-[-12px] h-8 w-8 text-white"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svga"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="3"
                                d="M19 9l-7 7-7-7"
                            ></path>
                        </svg>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Banner

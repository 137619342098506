import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import StateLogo from '../../../components/StateLogo'

interface DesktopVideoCallHeaderProps {
    phone: string
    display_phone: string
}

const DesktopVideoCallHeader: React.FC<DesktopVideoCallHeaderProps> = ({ phone, display_phone }) => {


    const [hasScrolled, setHasScrolled] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset
            if (scrollTop > 0) {
                setHasScrolled(true)
            } else {
                setHasScrolled(false)
            }
        }

        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    return (
        <div
            className={`fixed left-0 right-0 top-0 z-[1000]  ${hasScrolled ? 'bg-white shadow-2xl' : ''}`}
        >
            <div
                className={`mx-auto hidden max-w-[1510px] items-center justify-between px-[16px] md:flex md:bg-[unset] md:px-[50px] lg:px-[120px] `}
            >
                <Link
                    className="ml-0  bg-white md:rounded-b-[16px] md:px-[20px] md:py-[16px] "
                    to="/"
                >
                    <StateLogo className="h-[48px]" />
                </Link>
                <div className="flex">
                    <button>
                        <a href={`tel:${phone}`}>
                            <strong className="md:hidden">
                                <svg width="90" height="34"></svg>
                            </strong>
                            <span
                                className={`leading-normal sm:hidden md:flex ${hasScrolled ? 'text-Black100' : 'text-white'}`}
                            >
                                <svg
                                    width="17"
                                    height="17"
                                    viewBox="0 0 17 17"
                                    fill="none"
                                    className="mr-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        className="cls"
                                        d="M5.125 1.78125L6.375 4.78125C6.59375 5.28125 6.46875 5.875 6.03125 6.21875L4.5 7.5C5.53125 9.6875 7.3125 11.4688 9.5 12.5L10.7812 10.9688C11.125 10.5312 11.7188 10.4062 12.2188 10.625L15.2188 11.875C15.8125 12.0938 16.0938 12.75 15.9375 13.3438L15.1875 16.0938C15.0312 16.625 14.5625 17 14 17C6.25 17 0 10.75 0 3C0 2.4375 0.375 1.96875 0.90625 1.8125L3.65625 1.0625C4.25 0.90625 4.90625 1.1875 5.125 1.78125Z"
                                        fill={
                                            hasScrolled
                                                ? '#484848'
                                                : 'white'
                                        }
                                    ></path>
                                </svg>
                                Call Now - Open 24/7
                            </span>
                            <span
                                className={`text-[28px] font-extrabold leading-[28px] hover:underline  sm:hidden md:block ${hasScrolled ? 'text-Black100' : 'text-white'}`}
                            >
                                {display_phone}
                            </span>
                        </a>
                    </button>
                </div>
            </div>
        </div>

    )
}

export default DesktopVideoCallHeader



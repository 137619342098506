import React, { useState } from 'react'
import MenuIcon from '../../../assets/images/MenuIcon.png'
import { Link, useLocation } from 'react-router-dom'
import StateLogo from '../../../components/StateLogo'

const MobileMenu: React.FC = () => {
    const [expandMenu, setExpandMenu] = useState(false)

    const location = useLocation()

    const toggleMenu = () => {
        setExpandMenu(!expandMenu)
    }

    return (
        <div>
            <div className="flex justify-between bg-Neutral000 p-[16px] sm:h-[56px]  md:flex lg:hidden">
                <Link to="/">
                    <StateLogo className="sm: h-[36px] " />
                    {/* <img src={Logo} alt="logo" className="sm: h-[36px] " /> */}
                </Link>
                <button onClick={toggleMenu}>
                    <img
                        src={MenuIcon}
                        alt="menu"
                        className="h-6 w-6 text-gray-400"
                        aria-hidden="true"
                    />
                </button>
            </div>

            {expandMenu && (
                <div className="font-Arial fixed inset-0 z-50 transition-all duration-300 ease-in-out">
                    <div className="fixed inset-y-0  right-0 w-4/5 bg-MainColor bg-opacity-95 text-[20px] text-white  shadow-lg">
                        <button
                            className="justify-left pl-[20px] pt-[20px]"
                            onClick={toggleMenu}
                        >
                            X
                        </button>
                        {location.pathname.startsWith('/request') ? (
                            <ul className="p-4 text-right">
                                <li className="mr-7 pt-[10px]">
                                    <span>About Us</span>
                                </li>
                                <li className="mr-7 pt-[10px]">
                                    <span>Services</span>
                                </li>
                                <li className="mr-7 pt-[10px]">
                                    <span>Results</span>
                                </li>
                                <li className="mr-7 pt-[10px]">
                                    <span>FAQ</span>
                                </li>
                            </ul>
                        ) : (
                            <ul className="p-4 text-right">
                                <li className="mr-7 pt-[10px]">
                                    <a href="#aboutus">About Us</a>
                                </li>
                                <li className="mr-7 pt-[10px]">
                                    <a href="#services">Services</a>
                                </li>
                                <li className="mr-7 pt-[10px]">
                                    <a href="#reviews">Results</a>
                                </li>
                                <li className="mr-7 pt-[10px]">
                                    <a href="#faq">FAQ</a>
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default MobileMenu

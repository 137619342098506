import React, { useState } from 'react'
import Star from '../../assets/images/star.png'
import PadLock from '../../assets/images/PadLock.png'
import { analytics } from '../../config/firebase'
import { logEvent } from 'firebase/analytics'
import CityAutoComplete from '../CityAutoComplete'
import ButtonCTA from '../ButtonCTA'
import { useLocation } from 'react-router-dom'

interface BannerProps {
    updateHide: () => void
    updateCity: (city: string) => void
    updateState: (state: string) => void
    area?: string
    formHeadline: string
}

const BannerForm: React.FC<BannerProps> = ({
    updateHide,
    updateCity,
    updateState,
    area,
    formHeadline
}) => {
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [locationError, setLocationError] = useState(false)

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const isFreeConsultation =
        searchParams.get('isFreeConsultation') !== 'false'

    const handleSubmit = () => {
        if (city !== '' && state !== '') {
            logEvent(analytics, 'location_entered')
            updateCity(city)
            updateState(state)
            updateHide()
        } else {
            setLocationError(true)
        }
    }

    return (
        <div className="rounded-[16px] border-[1px] border-Neutral300 bg-Neutral000 px-[16px] pb-[16px] pt-[20px] text-[18px] shadow-2xl md:px-[20px] md:pb-[20px] md:pt-[25px]">
            {isFreeConsultation ? (
                <h2 className="font-Leitura max-w-[670px] text-center text-[24px] text-Black100 md:text-[27px]  " dangerouslySetInnerHTML={{ __html: formHeadline }}>
                </h2>
            ) : (
                <h2 className="font-Leitura max-w-[670px] text-center text-[24px] text-Black100 md:text-[27px]  ">
                    Your Questions Answered <br />
                </h2>
            )}
            <CityAutoComplete
                setCity={setCity}
                setState={setState}
                isValid={city !== '' && state !== ''}
                city={city}
                state={state}
                locationError={locationError}
                setLocationError={setLocationError}
            />
            {/* <p className="text-[11px] text-Black400 mb-[8px]">
                We Guarantee 100% Privacy. Your information will not be shared.
            </p> */}
            <ButtonCTA
                className="mb-[8px] mt-[30px] flex justify-center text-[18px]"
                onClick={handleSubmit}
            />
            <div className="flex items-center justify-center text-[13px]">
                <img src={Star} alt="star icon" className="h-[10px] w-[10px]" />
                <img src={Star} alt="star icon" className="h-[10px] w-[10px]" />
                <img src={Star} alt="star icon" className="h-[10px] w-[10px]" />
                <img src={Star} alt="star icon" className="h-[10px] w-[10px]" />
                <img src={Star} alt="star icon" className="h-[10px] w-[10px]" />

                <span className="ml-[8px] text-[12px]">
                    4.8 Lawyers On Google
                </span>
                <img
                    src={PadLock}
                    className="ml-[8px] h-[20px] w-[13px]"
                    alt="pad lock icon"
                />
            </div>
        </div>
    )
}

export default BannerForm

import React from 'react'
// import { logEvent } from 'firebase/analytics'
// import { analytics } from '../../../config/firebase'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import Attorney from './Attorney'
// import { getStateNameFromUrl } from '../../../utils'

const attorneys = [
    {
        id: 1,
        name: 'Samer Habbas',
        location: 'Orange County, CA',
        firm_name: 'Samer Habbas & Associates',
        credentials: [
            'Califronia State Bar 2006',
            'Whittier School of Law, Magna Cum Laude',
        ],
        law_type: [
            'Personal Injury',
            'Motor Vehicle Accidents',
            'Wrongful Death',
        ],
        picture: 'https://admin.firmleads.io/media/samer-habbas.jpg',
        bio: `Samer Habbas, PC, is a personal injury attorney in Southern California. After graduating cum laude from Whittier Law School, Habbas gained experience at personal injury firms and witnessed insurance companies undervaluing claims. Driven to fight for fair compensation, he established his own client-focused firm, which has recovered millions over the past 13 years. Habbas actively contributes to nonprofits like Children's Hospital OC and local food banks.`,
        memberships: [
            'Multi-Million Dollar Advocates Forum',
            'Consumer Attorneys Association of Los Angeles (CAALA)',
            'Orange County Trial Lawyers Association',
            'California Brain Injury Association (CBIA)',
            'Consumer Attorneys of California (CAOC)',
        ],
    },
    {
        id: 2,
        name: 'Adam Kocaj',
        location: 'Los Angeles, CA',
        firm_name: 'Samer Habbas & Associates',
        credentials: [
            'Michigan State Bar (2011)',
            'California State Bar (2018)',
            'Michigan State College of Law, Magna Cum Laude',
        ],
        law_type: ['Personal Injury', 'Motor Vehicle Accidents', 'Dog Bite'],
        picture: 'https://admin.firmleads.io/media/adam-kocaj.jpg',
        bio: 'Adam Kocaj is a skilled personal injury attorney at Law Offices of Samer Habbas & Associates. Born in Michigan, he earned his law degree magna cum laude from Michigan State. Driven to help people, Kocaj has resolved over 800 cases, recovering over $20 million for clients. His expertise includes motor vehicle accidents, premises liability, and wrongful death cases. Kocaj combines compassionate guidance with relentless advocacy, earning recognition as a 2021 Super Lawyers Rising Star.',
        memberships: [
            'Attorney and Practice Magazine Top 10 Attorney Award',
            'Super Lawyers Rising Star (2021-2022)',
            'Top-Rated Avvo Lawyer',
        ],
    },
    {
        id: 3,
        name: 'Michelle Hunter',
        location: 'Riverside, CA',
        firm_name: 'Samer Habbas & Associates',
        credentials: [
            'California State Bar (2021)',
            'Loyola Law School, Magna Cum Laude',
        ],
        law_type: [
            'Personal Injury',
            'Motor Vehicle Accidents',
            'Truck Accidents',
        ],
        picture: 'https://admin.firmleads.io/media/michelle-hunter.jpg',
        bio: 'Michelle Hunter is an Associate Attorney at Law Offices of Samer Habbas & Associates. A Southern California native, she graduated magna cum laude from Chapman University with a degree in English/Writing and minor in Applied Psychology. Hunter gained hands-on experience as a law clerk at the firm, exhibiting strong writing, research, and analytical skills. After graduating from Loyola Law School, where she earned multiple academic excellence awards, Hunter joined the firm as an associate. Her passion lies in using her talents in persuasive writing and communication to fight for clients rights. Hunter goes the distance, providing in-depth research and exceptional advocacy to ensure her clients receive justice.',
        memberships: [],
    },

    {
        id: 5,
        name: 'Muhammad Ali',
        location: 'San Bernardino, CA',
        firm_name: 'Samer Habbas & Associates',
        credentials: [
            'California State Bar (2023)',
            'Loyola Law School, Los Angeles',
        ],
        law_type: [
            'Personal Injury',
            'Motor Vehicle Accidents',
            'Motorcycle Accidents',
        ],
        picture: 'https://admin.firmleads.io/media/muhammad-ali.png',
        bio: "Muhammad Ali holds a Biology degree from Cal State LA and a J.D. from Loyola Law School, concentrating in litigation and criminal justice. Ali clerked at the LA Public Defender's Office and the firm, gaining valuable courtroom experience. His passion for trial advocacy was honed through coursework taught by practicing attorneys. Ali's diverse background, from science to insurance defense, provides a unique perspective in advocating for personal injury clients.",
        memberships: [
            'U.S. District Court, Central District of California',
            'Consumer Attorneys Association of Los Angeles (CAALA)',
            'Langston Bar Association of Los Angeles',
            'Muslim Bar Association of Southern CA',
        ],
    },
    {
        id: 6,
        name: 'Madison Carroll',
        location: 'Los Angeles, CA',
        firm_name: 'Samer Habbas & Associates',
        credentials: [
            'California State Bar (2021)',
            'Loyola Law School, Los Angeles',
        ],
        law_type: ['Personal Injury', 'Motor Vehicle Accidents', 'Back Injury'],
        picture: 'https://admin.firmleads.io/media/madison-carroll.jpeg',
        bio: "Madison Carroll is an Associate Attorney bringing prior experience in both plaintiff personal injury and medical malpractice defense. She earned her J.D. from Loyola Law School after graduating from UCLA. Carroll's background representing injured plaintiffs and defendant medical professionals provides valuable perspective. When not working, she enjoys outdoor activities and spending time with family in Irvine.",
        memberships: [],
    },
    {
        id: 4,
        name: 'Erik Zentz',
        location: 'San Diego, CA',
        firm_name: 'Samer Habbas & Associates',
        credentials: [
            'California State Bar (2019)',
            'University of Nevada, Las Vegas (UNLV)',
        ],
        law_type: [
            'Personal Injury',
            'Motor Vehicle Accidents',
            'DUI Accident Injury',
        ],
        picture: 'https://admin.firmleads.io/media/erik-zentz.png',
        bio: 'Erik Zentz is an Associate Attorney at Law Offices of Samer Habbas & Associates. Born in New Jersey and raised in Las Vegas, Zentz obtained his Criminal Justice degree from the University of Nevada, Las Vegas, where he played football and rugby. After graduating law school in San Diego in 2006, Zentz began his legal career as a prosecutor and criminal defense attorney in Las Vegas. He operated his own personal injury and criminal defense firm from 2008-2023 before relocating to San Diego. With over 1,000 trials under his belt, Zentz brings extensive courtroom experience fighting for clients rights. Joining the firm allowed him to pursue his dream of becoming a personal injury attorney, using his trial skills to secure deserved compensation.',
        memberships: [],
    },
    {
        id: 7,
        name: 'Honore Daly',
        location: 'Orange County, CA',
        firm_name: 'Samer Habbas & Associates',
        credentials: [
            'California State Bar (2023)',
            'California Western School of Law',
        ],
        law_type: [
            'Personal Injury',
            'Motor Vehicle Accidents',
            'Boat Accidents',
        ],
        picture: 'https://admin.firmleads.io/media/honore-daly.jpeg',
        bio: "Honoré Daly is an Associate Attorney at Law Offices of Samer Habbas & Associates. She holds a J.D. from California Western School of Law and gained experience through legal internships before joining the firm. Daly aims to leverage her business background and the firm's mentorship to advocate passionately for injured clients.",
        memberships: [],
    },
    {
        id: 8,
        name: 'Elias Fakhoury',
        location: 'San Diego, CA',
        firm_name: 'Samer Habbas & Associates',
        credentials: [
            'California State Bar (2021)',
            'Southwestern Law School ',
        ],
        law_type: [
            'Personal Injury',
            'Motor Vehicle Accidents',
            'Wrongful Death',
        ],
        picture: 'https://admin.firmleads.io/media/elias-fakhoury.png',
        bio: 'Elias Fakhoury is an Associate Attorney at Law Offices of Samer Habbas & Associates. He holds a Political Science degree from USC and a J.D. from Southwestern Law School. Prior to joining the firm, Fakhoury gained experience in business litigation, employment law, and personal injury cases at premier Los Angeles firms. An active member of legal associations like CAALA and CELA, he was named a Super Lawyers Rising Star in 2023 and 2024. In his free time, Fakhoury enjoys spending time with family and watching Lakers and Dodgers games.',
        memberships: [
            'Consumer Attorneys Association of LA',
            'Orange County Trial Lawyers Association',
            'California Employment Lawyers Association (CELA)',
        ],
    },
    {
        id: 9,
        name: 'Cinthya Corcho',
        location: 'Los Angeles, CA',
        firm_name: 'Samer Habbas & Associates',
        credentials: [
            'California State Bar (2021)',
            'Chapman University Fowler School of Law',
        ],
        law_type: [
            'Personal Injury',
            'Motor Vehicle Accidents',
            'Wrongful Death',
        ],
        picture: 'https://admin.firmleads.io/media/cinthya-corcho.png',
        bio: "Cinthya Corcho is an Associate Attorney at Law Offices of Samer Habbas & Associates. She graduated cum laude from Cal State Fullerton with a B.A. in Criminal Justice and earned her J.D. from Chapman University. Corcho gained valuable experience through internships at the Public Defender's Office and as a legal secretary prior to joining the firm. Fluent in English, Spanish, and Italian, she advocates compassionately yet tenaciously to ensure injured clients receive just compensation. Corcho's leadership background and commitment to justice drive her legal practice.",
        memberships: [],
    },
]

export function SampleNextArrow({ ...props }) {
    return (
        <button
            {...props}
            aria-label="next"
            className="next absolute right-[-10px] top-[50%]  rounded-full border  border-Neutral400 bg-[transparant] p-[10px] text-center sm:right-[-20px] md:right-[-40px] xl:right-[-50px]"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                fill="#484848"
                className="h-[20px] w-[20px]"
            >
                <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
            </svg>
        </button>
    )
}

export function SamplePrevArrow({ ...props }) {
    return (
        <button
            {...props}
            aria-label="previous"
            className="prev absolute left-[-10px] top-[50%] rounded-full border border-Neutral400 bg-[transparant] p-[10px] text-center sm:left-[-20px] md:left-[-40px]  xl:left-[-50px]"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                fill="#484848"
                className="h-[20px] w-[20px]"
            >
                <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
            </svg>
        </button>
    )
}
const Attorneys: React.FC = () => {
    return (
        <section>
            <div className="border border-transparent bg-BgColor pt-[50px] lg:pt-[100px]">
                <div className="mx-auto mb-[50px] w-full max-w-[1170px] md:mb-[100px] md:w-[90%] xl:w-[auto]">
                    <div className="md:flex md:items-center">
                        <div className="mb-[30px] flex w-full flex-col px-4 md:flex-row md:px-5">
                            <h2 className="font-Leitura mx-auto mb-[15px] text-center text-[24px] text-Black100 md:mb-0 md:w-[50%] md:text-[36px]">
                                Our Attorneys
                            </h2>
                            {/* <div className="mx-auto my-auto flex h-full w-full justify-center align-middle sm:w-[50%] md:mx-0 md:justify-end">
                                <a href="#form" className="w-full md:w-[unset]">
                                <ButtonCTA className='flex w-full justify-center'/>
                                 
                                </a>
                            </div> */}
                        </div>
                    </div>
                    <div className="mx-auto mt-[30px] max-w-[1170px] lg:mt-[0px]">
                        <div className=" flex max-w-[1170px] flex-col justify-between gap-[10px] md:flex-row lg:gap-[30px]">
                            <Slider
                                dots={false}
                                infinite={true}
                                speed={500}
                                slidesToShow={3}
                                slidesToScroll={1}
                                nextArrow={<SampleNextArrow />}
                                prevArrow={<SamplePrevArrow />}
                                centerMode={false}
                                responsive={[
                                    {
                                        breakpoint: 1024,
                                        settings: {
                                            slidesToShow: 2,
                                            slidesToScroll: 1,
                                        },
                                    },
                                    {
                                        breakpoint: 768,
                                        settings: {
                                            slidesToShow: 1,
                                            slidesToScroll: 1,
                                            centerMode: true,
                                            arrows: false,
                                        },
                                    },
                                ]}
                                autoplaySpeed={5000}
                                arrows={true}
                                className="h-full w-full"
                            >
                                {attorneys.map((attorney) => (
                                    <Attorney
                                        key={attorney.id}
                                        attorney={attorney}
                                    />
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Attorneys

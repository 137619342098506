import React from 'react'
import Man from '../../../assets/images/Man.jpg'
import { handlebarsOut } from '../../../utils'
import ButtonCTA from '../../../components/ButtonCTA'

interface AboutUsProps {
    updateHide: () => void
    content: any
}

const AboutUs: React.FC<AboutUsProps> = ({ updateHide, content }) => {
    return (
        <section id="aboutus">
            <div className="px-4 py-[50px] md:px-5 lg:py-[100px]">
                <div className="mx-auto flex max-w-[1170px] flex-col justify-between gap-[30px] lg:flex-row">
                    <div className="mx-auto max-w-[500px] lg:mx-[unset] ">
                        <img src={Man} alt="man" loading={'lazy'} className='rounded-[16px]' />
                    </div>
                    <div className="mx-auto my-auto h-fit max-w-[550px] lg:mx-[unset] lg:ml-auto">
                        <h2 className="font-Leitura text-[24px] text-Black100 md:text-[36px]" dangerouslySetInnerHTML={{ __html: handlebarsOut(content.headline) }}>
                        </h2>
                        <p className="font-Arial mt-[10px] max-w-[550px] text-[16px] text-Black400 md:text-[18px]" dangerouslySetInnerHTML={{ __html: handlebarsOut(content.description) }}>

                        </p>
                        <h3 className="font-Arial mt-[20px] inline-block border-b-[3px] border-SecondColor pb-[10px] text-[18px] font-bold lg:text-[20px]" dangerouslySetInnerHTML={{ __html: handlebarsOut(content.sub_headline1) }}>
                        </h3>
                        <p className="font-Arial mt-[10px] max-w-[550px] text-[16px] text-Black400 md:text-[18px]" dangerouslySetInnerHTML={{ __html: handlebarsOut(content.description1) }}>
                        </p>
                        <h3 className="font-Arial mt-[20px] inline-block border-b-[3px] border-SecondColor pb-[10px] text-[18px] font-bold lg:text-[20px]">

                            {content.sub_headline2}

                        </h3>
                        <p className="font-Arial  mt-[10px] max-w-[550px] text-[16px] text-Black400 md:text-[18px]">

                            {content.description2}
                        </p>
                        <a href="#form">
                            <ButtonCTA className="mt-[20px] md:mt-[30px] lg:mt-[40px]" />
                        </a>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default AboutUs

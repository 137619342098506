import React, { useEffect } from 'react'
import Router from './routes'
import './assets/css/custom.scss'
import { theme_map, title_map, description_map } from './config/general'
import { getDomainName } from './utils'

function App() {
    useEffect(() => {
        const scrollToSection = (sectionId: any) => {
            const section = document.getElementById(sectionId)
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' })
            }
        }

        const sectionIds = ['faq', 'aboutus', 'reviews', 'services']

        const currentHash = window.location.hash.substring(1)

        if (sectionIds.includes(currentHash)) {
            scrollToSection(currentHash)
        }
    }, [])

    useEffect(() => {
        let hostname =
            getDomainName(window.location.hostname) || 'californialawconnect'

        console.log('hostname', hostname)

        if (hostname === 'localhost') {
            hostname = 'californialawconnect'
        }

        document.body.className = theme_map[hostname] || 'ca-theme'

        const link: any =
            document.querySelector("link[rel*='icon']") ||
            document.createElement('link')
        link.type = 'image/x-icon'
        link.rel = 'shortcut icon'
        link.href = `https://firebasestorage.googleapis.com/v0/b/firmleads-production.appspot.com/o/landing_pages%2F${hostname}%2Ffavicon.ico?alt=media`
        document.getElementsByTagName('head')[0].appendChild(link)

        const check_manifest =
            document.querySelector("link[rel='manifest']") === null

        if (check_manifest) {
            const manifest = document.createElement('link')
            manifest.rel = 'manifest'

            manifest.href = `https://firebasestorage.googleapis.com/v0/b/firmleads-production.appspot.com/o/landing_pages%2F${hostname}%2Fmanifest.json?alt=media`

            document.head.appendChild(manifest)
        }

        document.title = title_map[hostname]

        let metaDescription = document.querySelector("meta[name='description']")
        if (!metaDescription) {
            metaDescription = document.createElement('meta')
        }
        metaDescription.setAttribute('name', 'description')
        document.head.appendChild(metaDescription)
        metaDescription.setAttribute('content', description_map[hostname])
    }, [])

    return <Router />
}

export default App

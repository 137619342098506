import React from 'react'
import { Link } from 'react-router-dom'
import StateLogo from '../../../components/StateLogo'
import menu from '../../../assets/images/MenuIcon.svg'

interface MobileVideoCallHeaderProps {}

const MobileVideoCallHeader: React.FC<MobileVideoCallHeaderProps> = ({}) => {
    return (
        <div className=" flex w-full items-center justify-between bg-white p-[16px] md:hidden">
            <Link
                className="ml-0 md:ml-[50px] md:rounded-b-[16px] md:px-[20px] md:py-[16px] lg:ml-[80px]"
                to="/"
            >
                <StateLogo className="h-[36px]" />
            </Link>
            <Link to="/">
                <img src={menu} alt="Menubar" />
            </Link>
        </div>
    )
}

export default MobileVideoCallHeader

import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from './Footer'

const FunnelLayout: React.FC = () => (
    <div>
        <Outlet />
        <Footer />
    </div>
)

export default FunnelLayout

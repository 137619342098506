import React, { useEffect, useState } from 'react'
import PlanDetail from './PlanDetail'
import BannerDetailV4 from './BannerV4'
import NoHugeFees from './NoHugeFees'
import LawOverview from './LawOverview'
import FreeConsultation from './FreeConsultation'
import Stats from './Stats'
import WhyLawyer from './WhyLawyer'
import Reviews from './Reviews'
import AboutUs from './AboutUs'
import FAQ from './FAQ'
import Funnel from '../FunnelV3'
import content from '../../assets/webcopy.json'
import { useParams } from 'react-router-dom'
import Form from './FormV2'
import { LandingPage } from '../../../payload'
import Attorneys from './Attorneys'
import { getDomainName } from '../../utils'
// import WhyAffordable from './WhyAffordable'
type Content = { [key: string]: any }

const Landing: React.FC = () => {
    const [hideLanding, setHideLanding] = useState(false)
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [page, setPage] = useState<LandingPage>()

    const location_split = window.location.hostname.split('.')
    const landing_url =
        location_split[location_split.length - 2] +
        '.' +
        location_split[location_split.length - 1]

    let subdomain = location_split[0]

    console.log('landing_url', landing_url)

    useEffect(() => {
        const handlePopState = (event: PopStateEvent) => {
            if (event.state && event.state.formOpen) {
                setHideLanding(false);
            }
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (
                subdomain === '' ||
                subdomain === undefined ||
                subdomain === null
            )
                return

            console.log('subdomain', subdomain)
            try {
                const response = await fetch(
                    `https://admin.firmleads.io/api/landing-pages?where[subdomain.subdomain_name][equals]=${subdomain}`
                )
                const data = await response.json()
                setPage(data.docs[0])
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }

        fetchData()
    }, [subdomain])

    let page_content = (content as Content)[subdomain]

    if (page_content === undefined) {
        subdomain = 'family'
        page_content = (content as Content)['family']
    }

    const updateCity = (city: string) => {
        setCity(city)
    }

    const updateState = (state: string) => {
        setState(state)
    }
    const updateHideLanding = () => {
        if (!hideLanding) {
            window.history.pushState({ formOpen: true }, '');
        } else {
            window.history.go(-1);
        }
        setHideLanding(!hideLanding);
    };

    let { lawArea, location } = useParams()
    // console.log('location---', location)
    // console.log('page---', page)

    // const renderComponent = (block: any) => {
    //     switch (block.blockType) {
    //         case 'alder':
    //             return (
    //                 <BannerDetailV4
    //                     law_category={block.law_category}
    //                     video={block.background_video.url}
    //                     img={block.background_img_url}
    //                     law_area={lawArea}
    //                     location={location}
    //                     connect_bool={false}
    //                     headline={block.headline}
    //                 />
    //             )
    //         case 'banana':
    //             return (
    //                 <Form
    //                     updateHide={updateHideLanding}
    //                     updateCity={updateCity}
    //                     updateState={updateState}
    //                     law_category={block.law_category}
    //                     benefits={[
    //                         block.benefit_1,
    //                         block.benefit_2,
    //                         block.benefit_3,
    //                     ]}
    //                     reviews={block.reviews}
    //                     area={subdomain}
    //                     headline2={page_content.headline2}
    //                 />
    //             )
    //         case 'cabbage':
    //             return (
    //                 <PlanDetail
    //                     updateHide={updateHideLanding}
    //                     law_category={block.law_category}
    //                 />
    //             )
    //         case 'drewberry':
    //             return (
    //                 <LawOverview
    //                     updateHide={updateHideLanding}
    //                     content={block}
    //                 />
    //             )
    //         case 'eytelia':
    //             return <Stats />
    //         case 'fennel':
    //             return <NoHugeFees />
    //         case 'garlic':
    //             return <Reviews updateHide={updateHideLanding} />
    //         case 'hellebore':
    //             return <FreeConsultation />
    //         case 'why-affordable':
    //             return <WhyAffordable />
    //         case 'inkberry':
    //             return <WhyLawyer content={block} law_area={lawArea!} />
    //         case 'jasmine':
    //             return <AboutUs updateHide={updateHideLanding} />
    //         case 'kudzu':
    //             return <FAQ updateHide={updateHideLanding} faqs={block} />
    //         default:
    //             return null
    //     }
    // }

    if (hideLanding) {
        return (
            <div className={hideLanding ? 'block' : 'hidden'}>
                <Funnel
                    updateHide={updateHideLanding}
                    landing_page={subdomain}
                    landing_url={landing_url}
                    city={city}
                    state={state}
                    initialFunnel={page?.variants![0].funnel!}
                    contactPrompt={page?.variants![0].contact_prompt!}
                    scrollText={page_content.scroll_text}
                />
            </div>
        )
    }

    // return (
    //     <>
    //         {page?.variants?.[0]?.layout?.map((block, index) => (
    //             <React.Fragment key={index}>
    //                 {renderComponent(block)}
    //             </React.Fragment>
    //         ))}
    //     </>
    // )

    return (
        <>
            <BannerDetailV4
                call_header={page_content.call_header}
                phone={page_content.phone}
                display_phone={page_content.display_phone}
                law_category={page_content.name}
                video={page_content.background.video}
                img={page_content.background.img}
                law_area={lawArea}
                location={location}
                connect_bool={false}
                headline={page_content.headline}
            />
            <Form
                updateHide={updateHideLanding}
                updateCity={updateCity}
                updateState={updateState}
                law_category={page_content.name}
                benefits={page_content.benefits}
                reviews={page_content.reviews}
                area={subdomain}
                headline2={page_content.headline2}
                scrollText={page_content.scroll_text}
                formHeadline={page_content.form_headline}
            />
            <PlanDetail
                updateHide={updateHideLanding}
                law_category={page_content.name}
                content={page_content.steps}
            />
            <LawOverview
                updateHide={updateHideLanding}
                content={page_content.law_overview}
            />
            <Stats avgStartCost={page_content.avg_start_cost} />
            <NoHugeFees content={page_content.fees} />
            <Reviews updateHide={updateHideLanding} reviews={page_content.reviews_lower} />
            <FreeConsultation content={page_content.consultation} />
            <WhyLawyer
                content={page_content.why_lawyer}
                law_area={page_content.name}
            />
            <AboutUs updateHide={updateHideLanding} content={page_content.about_us} />
            {subdomain === 'auto' &&
                getDomainName(window.location.hostname) ===
                'californialawconnect' && <Attorneys />}
            <FAQ updateHide={updateHideLanding} faqs={page_content.faq} final_cta={page_content.final_cta} />
        </>
    )
}

export default Landing

import React, { useEffect, useState } from 'react'
import LegalInfo from '../../components/LegalInfo'

const Privacy: React.FC = () => {
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    'https://admin.firmleads.io/api/pages/661d9f2de29c7eee12b63b1d?locale=undefined&draft=true&depth=1'
                )
                const jsonData = await response.json()
                setData(jsonData.layout[0])
                setLoading(false)
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }

        fetchData()
    }, [])

    if (loading)
        return (
            <div className="h-screen w-full items-center justify-center">
                <div className="loader mx-auto mb-[30px] mt-[50px] lg:mx-0 lg:mb-0 lg:mr-[30px] lg:mt-0"></div>
            </div>
        )

    return <LegalInfo {...data} />
}

export default Privacy

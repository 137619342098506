import React from 'react'
import VectorIcon from '../../assets/images/VectorIcon.svg'
import { Link } from 'react-router-dom'
import StateLogo from '../../components/StateLogo'
import { getDomainNameReadable } from '../../utils'

const Footer: React.FC = () => (
    <div className="mt-[150px] min-h-[280px] bg-Neutral000 px-4 pb-[20px] md:px-5">
        <div className="mx-auto flex max-w-[1170px] flex-col items-start justify-between sm:flex-row sm:items-center">
            <Link to="/">
                <StateLogo className="mb-[20px] h-[36px] sm:mb-0 md:h-[44px] lg:mb-0" />
            </Link>
            <div className="font-Arial flex w-full flex-wrap justify-between gap-[10px] text-Black100 sm:max-w-[735px] sm:justify-end sm:gap-[30px]">
                <Link className="lg:mr-[30px]" to="/privacy">
                    Privacy Policy
                </Link>
                <Link className="lg:mr-[30px]" to="/terms">
                    Terms of Service
                </Link>
                <Link className="lg:mr-[30px]" to="/legal-notice">
                    Legal Notice
                </Link>
                <Link className="lg:mr-[30px]" to="/privacy">
                    CA Notice
                </Link>
                <a href="https://www.firmleads.io">For Lawyers</a>
            </div>
        </div>
        <div className="font-Arial mx-auto block max-w-[1170px] justify-around py-[20px] text-[14px] text-Black400 sm:flex md:py-[30px] md:text-[16px]">
            This website may be considered advertising. The information on this
            website does not constitute legal advice, nor does it establish an
            attorney client relationship. Legal advice is only available through
            participating attorneys.{' '}
            {getDomainNameReadable(window.location.hostname)} is a group
            advertisement and not a lawyer referral service. No attorney-client
            relationship exists or will be formed between you and{' '}
            {getDomainNameReadable(window.location.hostname)} and the
            information you submit may not be protected by attorney-client
            privilege. Please do not send information that may be considered
            privileged or confidential.{' '}
            {getDomainNameReadable(window.location.hostname)} cannot and does
            not guarantee specific results. It does not endorse or recommend any
            lawyer or law firm who participates in the network. No
            representation or judgement has been made as to the qualifications,
            expertise or credentials of any participating lawyer, nor does it
            analyze a person's legal situation when determining which
            participating lawyers receive a person's inquiry. The sole basis for
            the inclusion of the participating lawyers or law firms is the
            payment of a fee for exclusive geographical advertising rights. All
            photos are of models and do not depict clients.
        </div>
        <div className="font-Arial mx-auto block max-w-[1170px] justify-between sm:flex">
            <div className="flex items-start text-[14px]">
                <img src={VectorIcon} alt="vector" className="my-auto" />
                <div className="pl-[5px] text-Black400">
                    2024 FirmLeads LLC. All rights reserved.
                </div>
            </div>

            <p className="flex gap-[20px] pt-[10px] text-[14px] text-Black400 sm:pt-[0px]">
                LEGAL HELP FOR EVERYONE
            </p>
        </div>
    </div>
)

export default Footer

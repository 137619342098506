import React from 'react'
import { getStateNameFromUrl } from '../../../utils'

interface StatsProps {
    avgStartCost: string
}

const Stats: React.FC<StatsProps> = ({ avgStartCost }) => (
    <div className="mt-[302px] border border-transparent bg-BgColor lg:mt-[108px]">
        <div className="-mt-[302px] px-4 lg:-mt-[109px] lg:px-5">
            <div className=" mx-auto max-w-[570px] justify-between rounded-[16px] bg-MainColor text-Neutral000 lg:flex lg:max-w-[1170px]">
                <div className="p-[25px] text-center lg:p-[50px] lg:text-left">
                    <p className=" font-Leitura text-[36px] lg:w-[px]">
                        7,236<span className="text-[20px]">+</span>
                    </p>
                    <p className=" font-Arial text-[16px] md:text-[18px] lg:mt-[10px] lg:w-full">
                        People of{' '}
                        {getStateNameFromUrl(window.location.hostname)}
                        <br /> Served
                    </p>
                </div>
                <div className="p-[25px] text-center lg:p-[50px] lg:text-left ">
                    <div className=" font-Leitura text-[36px] lg:w-[px]">
                        60 <span className="text-[20px]">Seconds</span>
                    </div>
                    <div className=" font-Arial text-[16px] md:text-[18px] lg:mt-[10px] lg:w-full">
                        Average Time to Connect
                        <br />
                        With a Lawyer
                    </div>
                </div>
                <div className="p-[25px] text-center lg:p-[50px] lg:text-left">
                    <div className=" font-Leitura text-[36px] lg:w-[px]">
                        {avgStartCost}
                        {/* $500
                        <span className="font-normal ">
                            <span className="text-[20px]"> - </span>
                        </span>
                        - $1,500 */}
                    </div>
                    <div className=" font-Arial text-[16px] md:text-[18px] lg:mt-[10px] lg:w-full">
                        Average <br /> Starting Cost
                    </div>
                </div>
                <div className="p-[25px] text-center lg:p-[50px] lg:text-left">
                    <div className=" font-Leitura text-[36px]  lg:w-[px]">
                        15<span className="text-[20px]"> +</span>
                    </div>
                    <div className=" font-Arial text-[16px] md:text-[18px] lg:mt-[10px] lg:w-full">
                        Years Serving{' '}
                        {getStateNameFromUrl(window.location.hostname)}
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default Stats

import React, { useState } from 'react'

const Lookup = () => {
    const [emailError, setEmailError] = useState(
        'We could not find a matching request. Try looking it up below.'
    )
    const [email, setEmail] = useState('')
    const [emailIsValid, setEmailIsValid] = useState(false)

    const validateEmail = () => {
        if (email.length < 10) {
            setEmailError('Please provided a valid input.')
            setEmailIsValid(false)
            return
        }
        const regex = /^[\w\.-]+@[\w\.-]+\.\w+$/

        if (!regex.test(email)) {
            setEmailError('Please provide a properly formatted email.')
            setEmailIsValid(false)
            return
        }

        //Do email check?
        // const textable = true
        // if (!textable) {
        //     setEmailError('Please provide a mobile phone number.')
        //     return
        // }
        setEmailError('')
        setEmailIsValid(true)
    }

    const handleSubmit = () => {}

    return (
        <div className="bg-BgColor py-[40px]">
            <div className="mx-auto  max-w-[770px] rounded-[16px] bg-Neutral000 p-5">
                <h2 className="font-Leitura mt-[30px] pb-[20px] text-center text-[24px] text-Black100 md:text-[36px]">
                    Lookup Request
                </h2>
                <p className="font-Arial border-Neutral300 pb-[30px] text-center text-[16px] text-Black400 md:text-[18px]">
                    Use the form below to lookup a previously submitted request.
                </p>
                <input
                    placeholder="Email"
                    required
                    type="email"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}
                    onBlur={validateEmail}
                    className={`${
                        emailIsValid
                            ? 'is-valid'
                            : 'border-[1px] border-Neutral300'
                    } w-full rounded-[12px]   px-[20px] py-[16px] text-Black100`}
                />
                <span
                    className={
                        (emailError !== '' ? 'block' : 'hidden') +
                        ' w-full text-center text-Red600'
                    }
                >
                    {emailError}
                </span>
                <button
                    onClick={handleSubmit}
                    className="font-Arial bg-MainColor mt-[20px] w-full rounded-[8px] px-[16px] py-[16px] text-[16px] font-bold text-Neutral000 lg:px-[30px] lg:text-[18px]"
                >
                    Lookup Request
                </button>
            </div>
        </div>
    )
}

export default Lookup

import React from 'react'
import DesktopMenu from './DesktopMenu'
import MobileMenu from './MobileMenu'

const Header: React.FC = () => (
    <div>
        <div className="relative mx-auto max-w-[1920px]">
            <DesktopMenu />
            <MobileMenu />
        </div>
    </div>
)

export default Header

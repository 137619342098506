import React from 'react'

interface FloatingChatInputBoxProps {
    isTyping: boolean
    chatMessage: string
    connecting: boolean
    sendMessageHandler: (e: any) => void
    setChatMessage: (value: string) => void
}

export const FloatingChatInputBox: React.FC<FloatingChatInputBoxProps> = ({
    isTyping,
    chatMessage,
    connecting,
    sendMessageHandler,
    setChatMessage,
}) => {
    return (
        <>
            {isTyping && !connecting && (
                <p className="ml-4 text-Neutral500">
                    Izan is typing<span className="typing-dots"></span>
                </p>
            )}
            {connecting && (
                <p className="ml-4 text-Black500">
                    Connecting you to an agent
                    <span className="typing-dots"></span>
                </p>
            )}
            <div className="chatbot-footer border-t border-Neutral300 px-5 pb-[15px] pt-[15px] text-Black100 sm:pb-[30px]">
                <form
                    onSubmit={(e) => sendMessageHandler(e)}
                    className="flex items-center space-x-4"
                >
                    {/* <button type="button">
            <img
                src={Pin}
                alt="Pin"
                className="h-6 w-6"
            />
        </button> */}
                    <input
                        type="text"
                        placeholder="Write your message..."
                        className="w-full rounded-xl border-none bg-Neutral000 p-3 placeholder:text-Neutral400 focus:border-none focus:outline-none focus:ring-0"
                        onChange={(e) => setChatMessage(e.target.value)}
                        value={chatMessage}
                    />
                    <button type="submit" disabled={chatMessage === ''}>
                        <svg
                            width="26"
                            height="26"
                            viewBox="0 0 26 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M24.3438 1.28125C24.8125 1.60938 25.0469 2.17188 24.9531 2.73438L21.9531 22.2344C21.9062 22.7031 21.625 23.0781 21.2031 23.3125C20.8281 23.5469 20.3125 23.5938 19.8906 23.4062L14.3125 21.0625L11.0781 24.5312C10.6562 25 10 25.1406 9.4375 24.9062C8.875 24.7188 8.5 24.1562 8.5 23.5V19.6094C8.5 19.4219 8.54688 19.2344 8.6875 19.0938L16.5156 10.5156C16.7969 10.2344 16.7969 9.76562 16.5156 9.48438C16.2344 9.20312 15.7656 9.20312 15.4844 9.48438L5.96875 17.9219L1.79688 15.8594C1.32812 15.625 1 15.1094 1 14.5469C0.953125 14.0312 1.23438 13.5156 1.75 13.2344L22.75 1.23438C23.2188 0.953125 23.875 0.953125 24.3438 1.28125Z"
                                fill={`${chatMessage === '' ? '#e9e9e9' : '#707070'}`}
                            />
                        </svg>

                        {/* <img
                src={Send}
                alt="Send"
                className="w-7"
            /> */}
                    </button>
                </form>
            </div>
        </>
    )
}

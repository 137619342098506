import React from 'react'
import { Link } from 'react-router-dom'
import StateLogo from '../../../components/StateLogo'
import menu from '../../../assets/images/MenuIcon.svg'

const DesktopVideoHeader: React.FC = () => {
    return (

        <div className="absolute  hidden w-full items-center justify-between bg-White p-[16px] md:flex md:w-auto md:bg-[unset] md:p-0">
            <Link

                className="ml-0 md:ml-[50px] md:rounded-b-[16px] md:bg-white md:px-[20px] md:py-[16px] lg:ml-[80px]"
                to="/"
            >
                <StateLogo className="h-[48px]" />
            </Link>
            <Link to="/contact" className="md:hidden">
                <img src={menu} alt="Menubar" />
            </Link>
        </div>)
}

export default DesktopVideoHeader

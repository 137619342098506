import { RadioGroup } from '@headlessui/react'
import { useState } from 'react'

interface Option {
    label: string
    value: string | { [key: string]: any }
}

interface SelectionGroupProps {
    formData: any
    setFormData: (formData: any) => void
    label: string
    options: Option[]
    subnote?: string
    name: string
}

const SelectionGroup: React.FC<SelectionGroupProps> = ({
    formData,
    setFormData,
    options,
    label,
    subnote,
    name,
}) => {
    const [selected, setSelected] = useState<Option>({
        label: '',
        value: '',
    })

    return (
        <div className="w-full px-4 pb-16">
            <div className="mx-auto w-full max-w-md">
                <RadioGroup
                    value={selected}
                    onChange={(selected: any) => {
                        setSelected(selected)
                        setFormData({ ...formData, [name]: selected.value })
                        let found = false
                        const updatedFunnelResponses =
                            formData.funnel_responses.map((item: any) => {
                                if (item.type === name) {
                                    console.log('in found')
                                    found = true
                                    return {
                                        ...item,
                                        value: selected.value,
                                        value_label: selected.label,
                                    }
                                }
                                return item
                            })

                        if (!found) {
                            updatedFunnelResponses.push({
                                type: name,
                                label,
                                value: selected.value,
                                value_label: selected.label,
                            })
                        }

                        setFormData({
                            ...formData,
                            funnel_responses: updatedFunnelResponses,
                        })
                    }}
                >
                    <RadioGroup.Label className="sr-only">
                        Budget
                    </RadioGroup.Label>
                    <div className="space-y-2">
                        {options.map((plan) => (
                            <RadioGroup.Option
                                key={plan.label}
                                value={plan}
                                className={({ active, checked }) =>
                                    `${
                                        active
                                            ? 'ring-2 ring-white/60 ring-offset-2 '
                                            : ''
                                    }
                  ${
                      selected && selected.label === plan.label
                          ? 'bg-MainColor text-white'
                          : 'border-[.25px] border-Neutral400 bg-white'
                  }
                    relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                                }
                            >
                                {({ active, checked }) => (
                                    <>
                                        <div className="flex w-full items-center justify-between">
                                            <div className="flex items-center">
                                                <div className="text-sm">
                                                    <RadioGroup.Label
                                                        as="p"
                                                        className={`font-medium  ${
                                                            selected &&
                                                            selected.label ===
                                                                plan.label
                                                                ? 'text-white'
                                                                : 'text-gray-900'
                                                        }`}
                                                    >
                                                        {plan.label}
                                                    </RadioGroup.Label>
                                                    {/* <RadioGroup.Description
                                                        as="span"
                                                        className={`inline ${
                                                            checked
                                                                ? 'text-sky-100'
                                                                : 'text-gray-500'
                                                        }`}
                                                    >
                                                        <span>
                                                            {plan.ram}/
                                                            {plan.cpus}
                                                        </span>{' '}
                                                        <span aria-hidden="true">
                                                            &middot;
                                                        </span>{' '}
                                                        <span>{plan.disk}</span>
                                                    </RadioGroup.Description> */}
                                                </div>
                                            </div>
                                            {selected &&
                                                selected.label ===
                                                    plan.label && (
                                                    <div className="shrink-0 text-blue-500">
                                                        <CheckIcon className="h-6 w-6" />
                                                    </div>
                                                )}
                                        </div>
                                    </>
                                )}
                            </RadioGroup.Option>
                        ))}
                    </div>
                </RadioGroup>
                {/* <p className="ml-2 mt-3 text-sm text-Neutral500">{subnote}</p> */}
            </div>
        </div>
    )
}

export default SelectionGroup

function CheckIcon(props: any) {
    return (
        <svg viewBox="0 0 24 24" fill="none" {...props}>
            <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
            <path
                d="M7 13l3 3 7-7"
                stroke="#fff"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

import React from 'react'
import { getDomainName } from '../../utils'

interface StateLogoProps {
    className?: string
    white?: boolean
}

const StateLogo: React.FC<StateLogoProps> = ({ className, white }) => {
    return (
        <img
            src={require(
                `../../assets/images/logos/logo-${getDomainName(window.location.hostname)}${white ? 'White' : ''}.svg`
            )}
            alt={`${window.location.hostname} Logo`}
            className={`${className} `}
        />
    )
}

export default StateLogo

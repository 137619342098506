export const getArticle = (str: string): string => {
    const vowelSounds = ['a', 'e', 'i', 'o', 'u']
    const firstLetter = str.toLowerCase().charAt(0)

    if (vowelSounds.includes(firstLetter)) {
        return 'an'
    } else {
        return 'a'
    }
}
export const addArticle = (str: string) => {
    return getArticle(str) + ' ' + str
}

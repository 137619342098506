import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './index.css'
import TagManager from 'react-gtm-module'

TagManager.initialize({ gtmId: 'GTM-KZKX9P9T' })

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
)

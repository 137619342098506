import React, { useEffect, useState } from 'react'
import LegalInfo from '../../components/LegalInfo'

const Terms: React.FC = () => {
    const [data, setData] = useState({})

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    'https://admin.firmleads.io/api/pages/661d9f2de29c7eee12b63b1d?locale=undefined&draft=true&depth=1'
                )
                const jsonData = await response.json()
                setData(jsonData.layout[0])
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }

        fetchData()
    }, [])

    return <LegalInfo {...data} />
}

export default Terms

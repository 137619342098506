import React, { useState, useEffect, useRef } from 'react'
import { handlebarsOut } from '../../utils'

interface ScrollTextProps {
    parentRef: any
    content: string[]
}

const ScrollText: React.FC<ScrollTextProps> = ({ parentRef, content }) => {
    const [parentWidth, setParentWidth] = useState(0)
    const [liWidths, setLiWidths] = useState<number[]>([0, 0, 0, 0])

    const liRefs = [useRef<HTMLLIElement>(null), useRef<HTMLLIElement>(null), useRef<HTMLLIElement>(null), useRef<HTMLLIElement>(null)]

    useEffect(() => {
        const handleResize = () => {
            if (parentRef.current) {
                setParentWidth(
                    (parentRef.current as HTMLDivElement).offsetWidth
                )
            }

            const newWidths = liRefs.map(ref => ref.current ? ref.current.offsetWidth : 0)
            setLiWidths(newWidths)
        }

        window.addEventListener('resize', handleResize)
        handleResize()

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    function getWidth(index: number): any {
        const textWidth = liWidths[index]
        const marginRight = (parentWidth * 4 - liWidths[3]) / 3 - textWidth
        return { marginRight: `${marginRight}px` }
    }

    return (
        <div className="my-4 w-full overflow-hidden">
            <ul className="animate-scrollX flex whitespace-nowrap text-Black100">
                <li
                    ref={liRefs[0]}
                    className={`flex items-center md:mr-[195px]`}
                    style={getWidth(0)}
                >
                    <svg
                        className="mr-2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                    >
                        <path
                            d="M6.29998 11.9002L2.56665 8.16689L3.28332 7.45023L6.29998 10.4669L12.7 4.06689L13.4167 4.78356L6.29998 11.9002Z"
                            fill="black"
                        ></path>
                    </svg>
                    <span dangerouslySetInnerHTML={{ __html: handlebarsOut(content[0]) }}></span>
                </li>
                <li
                    ref={liRefs[1]}
                    className={`flex items-center md:mr-[160px]`}
                    style={getWidth(1)}
                >
                    <svg
                        className="mr-2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                    >
                        <path
                            d="M6.29998 11.9002L2.56665 8.16689L3.28332 7.45023L6.29998 10.4669L12.7 4.06689L13.4167 4.78356L6.29998 11.9002Z"
                            fill="black"
                        ></path>
                    </svg>
                    <span dangerouslySetInnerHTML={{ __html: handlebarsOut(content[1]) }}></span>
                </li>
                <li
                    ref={liRefs[2]}
                    className={`flex items-center md:mr-[160px]`}
                    style={getWidth(2)}
                >
                    <svg
                        className="mr-2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                    >
                        <path
                            d="M6.29998 11.9002L2.56665 8.16689L3.28332 7.45023L6.29998 10.4669L12.7 4.06689L13.4167 4.78356L6.29998 11.9002Z"
                            fill="black"
                        ></path>
                    </svg>
                    <span dangerouslySetInnerHTML={{ __html: handlebarsOut(content[2]) }}></span>
                </li>
                <li
                    ref={liRefs[3]}
                    className="flex items-center"
                    style={{ marginRight: `${parentWidth}px` }}
                >
                    <svg
                        className="mr-2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                    >
                        <path
                            d="M6.29998 11.9002L2.56665 8.16689L3.28332 7.45023L6.29998 10.4669L12.7 4.06689L13.4167 4.78356L6.29998 11.9002Z"
                            fill="black"
                        ></path>
                    </svg>
                    <span dangerouslySetInnerHTML={{ __html: handlebarsOut(content[3]) }}></span>
                </li>
            </ul>
        </div>
    )
}

export default ScrollText

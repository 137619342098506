import React, { Fragment } from 'react'
import escapeHTML from 'escape-html'
import { BaseText, Text } from 'slate'

interface CustomText extends BaseText {
    bold?: boolean
    code?: boolean
    italic?: boolean
    underline?: boolean
    strikethrough?: boolean
    // Add any other custom leaf types here
}

const slateSerializer = (children: any) =>
    children.map((node: CustomText | any, i: number) => {
        if (Text.isText(node)) {
            const { text, bold, code, italic, underline, strikethrough } =
                node as CustomText

            let textElement: any = (
                <span
                    dangerouslySetInnerHTML={{
                        __html: escapeHTML(text).replace(/\n/g, '<br/>'),
                    }}
                />
            )

            if (bold) {
                textElement = <strong key={i}>{textElement}</strong>
            }

            if (code) {
                textElement = <code key={i}>{textElement}</code>
            }

            if (italic) {
                textElement = <em key={i}>{textElement}</em>
            }

            if (underline) {
                textElement = <u key={i}>{textElement}</u>
            }

            if (strikethrough) {
                textElement = <del key={i}>{textElement}</del>
            }

            // Add handling for other leaf types here
            // Example:
            // if (highlight) {
            //   textElement = <mark key={i}>{textElement}</mark>;
            // }

            return <Fragment key={i}>{textElement}</Fragment>
        }

        if (!node) {
            return null
        }

        switch (node.type) {
            case 'h1':
                return (
                    <h1
                        style={{ marginTop: '3rem', marginBottom: '.75rem' }}
                        key={i}
                    >
                        {slateSerializer(node.children)}
                    </h1>
                )
            case 'h2':
                return (
                    <h2
                        style={{
                            marginTop: '2.75rem',
                            marginBottom: '.75rem',
                        }}
                        key={i}
                    >
                        {slateSerializer(node.children)}
                    </h2>
                )
            case 'h3':
                return (
                    <h3
                        style={{ marginTop: '2.5rem', marginBottom: '.75rem' }}
                        key={i}
                    >
                        {slateSerializer(node.children)}
                    </h3>
                )
            case 'h4':
                return (
                    <h4
                        style={{
                            marginTop: '2.25rem',
                            marginBottom: '.75rem',
                        }}
                        key={i}
                    >
                        {slateSerializer(node.children)}
                    </h4>
                )
            case 'h5':
                return (
                    <h5
                        style={{ marginTop: '2rem', marginBottom: '.75rem' }}
                        key={i}
                    >
                        {slateSerializer(node.children)}
                    </h5>
                )
            case 'h6':
                return (
                    <h6
                        style={{
                            marginTop: '1.75rem',
                            marginBottom: '.75rem',
                        }}
                        key={i}
                    >
                        {slateSerializer(node.children)}
                    </h6>
                )
            case 'blockquote':
                return (
                    <blockquote key={i}>
                        {slateSerializer(node.children)}
                    </blockquote>
                )
            case 'ul':
                return (
                    <ul
                        style={{
                            marginTop: '1.5rem',
                            marginBottom: '1.5rem',
                            listStyleType: 'disc',
                            marginLeft: '1.5rem',
                        }}
                        key={i}
                    >
                        {slateSerializer(node.children)}
                    </ul>
                )
            case 'ol':
                return (
                    <ol
                        style={{
                            marginTop: '1.5rem',
                            marginBottom: '1.5rem',
                            listStyleType: 'decimal',
                            marginLeft: '1.5rem',
                        }}
                        key={i}
                    >
                        {slateSerializer(node.children)}
                    </ol>
                )
            case 'li':
                return (
                    <li
                        key={i}
                        style={{
                            fontSize: '18px',
                            marginBottom: '.75rem',
                        }}
                    >
                        {slateSerializer(node.children)}
                    </li>
                )
            case 'link':
                return (
                    <a
                        href={escapeHTML(node.url)}
                        key={i}
                        style={{
                            textDecoration: 'underline',
                        }}
                    >
                        {slateSerializer(node.children)}
                    </a>
                )
            default:
                return (
                    <p style={{ marginBottom: '1.5rem' }} key={i}>
                        {slateSerializer(node.children)}
                    </p>
                )
        }
    })

export default slateSerializer

import React, { useState, useEffect, useRef } from 'react'
import PinDrop from '../../assets/images/location.svg'

interface CityAutoCompleteProps {
    setCity: (city: string) => void
    setState: (state: string) => void
    setLocationError: (bool: boolean) => void
    city: string
    state: string
    isValid: boolean
    locationError: boolean
    className?: string
}

const CityAutoComplete: React.FC<CityAutoCompleteProps> = ({
    setCity,
    setState,
    setLocationError,
    isValid,
    city,
    state,
    locationError,
    className,
}) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [predictions, setPredictions] = useState([])
    const [input, setInput] = useState('')
    const [disablePredictions, setDisablePredictions] = useState(false)
    let autocomplete: any

    useEffect(() => {
        const setupAutocomplete = () => {
            if (window.google && autocomplete === undefined) {
                autocomplete =
                    new window.google.maps.places.AutocompleteService()

                inputRef.current?.addEventListener('input', handleInputChange)
            }
        }

        setupAutocomplete()

        return () => {
            inputRef.current?.removeEventListener('input', handleInputChange)
        }
    })

    const handleInputChange = () => {
        const inputValue = inputRef.current?.value

        if (inputRef.current) {
            autocomplete.getPlacePredictions(
                {
                    input: inputValue,
                    types: ['(cities)'],
                    componentRestrictions: {
                        country: 'us',
                    },
                },
                (results: any, status: any) => {
                    if (status === 'OK') {
                        setPredictions(results)
                    } else {
                        setPredictions([])
                    }
                }
            )
        }
    }

    const handleSelect = async (city: string, state: string) => {
        const address = `${city}, ${state}`
        setLocationError(false)
        setInput(address)
        setDisablePredictions(false)
        setCity(city)
        setState(state)
    }

    const onFormChange = (e: any) => {
        if (city !== '' && state !== '') {
            setCity('')
            setState('')
        }
        setInput(e.target.value)
    }

    return (
        <div className={`mt-[24px] ${className}`}>
            <span
                className={
                    (locationError ? 'block' : 'hidden') +
                    ' mb-[4px] w-full text-center text-Red600 lg:p-0'
                }
            >
                Please enter a location.
            </span>
            <div
                className={`w-full rounded-[12px] text-Black100 md:focus-within:outline md:focus-within:outline-2 md:focus-within:outline-offset-2 md:focus-within:outline-MainColor`}
            >
                <div className="flex w-full items-center rounded-[12px] border-2 border-MainColor p-[8px] focus-within:border-Neutral400 focus-within:outline focus-within:outline-2 focus-within:outline-offset-2 focus-within:outline-MainColor md:focus-within:outline-none">
                    <img
                        src={PinDrop}
                        className="mr-[8px] h-[24px]"
                        alt="pin drop icon"
                    />
                    <input
                        ref={inputRef}
                        value={input}
                        onChange={onFormChange}
                        onBlur={() => {}}
                        onFocus={() => {
                            setDisablePredictions(true)
                        }}
                        placeholder="Tell us where you are located!"
                        className={`w-full text-[17px] focus:outline-none md:text-[18px] ${
                            isValid ? 'is-valid' : ''
                        }`}
                    />
                </div>
            </div>

            {input !== '' && disablePredictions && (
                <div className="mx-[12px] rounded-[16px] pb-[8px] shadow-md ">
                    {predictions.map((suggestion: any, index: number) => (
                        <div
                            className="border-t-[1px] border-Neutral300 py-[5px] pl-[30px] pr-[8px] "
                            key={index}
                            onClick={() =>
                                handleSelect(
                                    suggestion.terms[0].value,
                                    suggestion.terms[1].value
                                )
                            }
                        >
                            {`${suggestion.terms[0].value}, ${suggestion.terms[1].value}`}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default CityAutoComplete

import React, { useEffect, useState } from 'react'

import Funnel from '../FunnelV3'
import content from '../../assets/webcopy.json'

import { LandingPage } from '../../../payload'
type Content = { [key: string]: any }

const DirectFunnel: React.FC = () => {
    const [page, setPage] = useState<LandingPage>()

    const location_split = window.location.hostname.split('.')
    const landing_url =
        location_split[location_split.length - 2] +
        '.' +
        location_split[location_split.length - 1]

    let subdomain = location_split[0]

    useEffect(() => {
        const fetchData = async () => {
            if (
                subdomain === '' ||
                subdomain === undefined ||
                subdomain === null
            )
                return

            console.log('subdomain', subdomain)
            try {
                const response = await fetch(
                    `https://admin.firmleads.io/api/landing-pages?where[subdomain][equals]=${subdomain}`
                )
                const data = await response.json()
                setPage(data.docs[0])
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }

        fetchData()
    }, [subdomain])

    let page_content = (content as Content)[subdomain]
    if (page_content === undefined) {
        subdomain = 'family'
        // page_content = (content as Content)['family']
    }

    console.log(page?.variants![0].funnel!)
    if (!page?.variants![0].funnel!) {
        return (
            <div className="flex min-h-screen items-center justify-center lg:pb-[200px]">
                <div className="loader mx-auto mb-[30px] mt-[50px] lg:mx-0 lg:mb-0 lg:mr-[30px] lg:mt-0"></div>
            </div>
        )
    }
    return (
        <Funnel
            updateHide={() => { }}
            landing_page={subdomain}
            landing_url={landing_url}
            city={''}
            state={''}
            initialFunnel={page?.variants![0].funnel!}
            direct_to_funnel={true}
            contactPrompt={page?.variants![0].contact_prompt!}
            scrollText={page_content.scroll_text}
        />
    )
}

export default DirectFunnel

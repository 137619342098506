import { Outlet, useRoutes } from 'react-router-dom'
import Admin from './layouts/Admin'
import Landing from './pages/Landing'
import Privacy from './pages/Privacy'
import Terms from './pages/Terms'
import FunnelLayout from './layouts/FunnelLayout'
import Request from './pages/Request'
import Lookup from './pages/Lookup'
import NoFound from './pages/NoFound'
import NotFound404 from './pages/NotFound404'
import Searching from './pages/Searching'
import DirectFunnel from './pages/DirectFunnel'
import LegalNotice from './pages/LegalNotice'
import FloatingChat from './components/FloatingChat'
import FloatingCallButton from './components/FloatingCallButton'

export default function Router() {
    const PrivateWrapper = ({ redirectPath = '/login', children }: any) => {
        return children ? children : <Outlet />
    }

    return (
        <div className="relative">
            <FloatingChat />
            <FloatingCallButton />
            {useRoutes([
                {
                    path: '/privacy',
                    element: (
                        <PrivateWrapper>
                            <Admin />
                        </PrivateWrapper>
                    ),
                    children: [{ path: '', element: <Privacy /> }],
                },
                {
                    path: '/terms',
                    element: (
                        <PrivateWrapper>
                            <Admin />
                        </PrivateWrapper>
                    ),
                    children: [{ path: '', element: <Terms /> }],
                },
                {
                    path: '/legal-notice',
                    element: (
                        <PrivateWrapper>
                            <Admin />
                        </PrivateWrapper>
                    ),
                    children: [{ path: '', element: <LegalNotice /> }],
                },
                {
                    path: '/f',
                    element: (
                        <PrivateWrapper>
                            <FunnelLayout />
                        </PrivateWrapper>
                    ),
                    children: [{ path: '', element: <DirectFunnel /> }],
                },

                {
                    path: '/request/lookup',
                    element: (
                        <PrivateWrapper>
                            <Admin />
                        </PrivateWrapper>
                    ),
                    children: [{ path: '', element: <Lookup /> }],
                },
                {
                    path: '/request',
                    element: (
                        <PrivateWrapper>
                            <Admin />
                        </PrivateWrapper>
                    ),
                    children: [{ path: '', element: <Request /> }],
                },
                {
                    path: '/nofound',
                    element: (
                        <PrivateWrapper>
                            <Admin />
                        </PrivateWrapper>
                    ),
                    children: [{ path: '', element: <NoFound /> }],
                },
                {
                    path: '/searching',
                    element: (
                        <PrivateWrapper>
                            <Admin />
                        </PrivateWrapper>
                    ),
                    children: [{ path: '', element: <Searching /> }],
                },
                // {
                //     path: '/logo192.png',
                //     element: (
                //         <img src={Logo512}/>
                //     ),
                //     children: [{ path: '', element: <Request /> }],
                // },
                {
                    path: '/request/:request_id',
                    element: (
                        <PrivateWrapper>
                            <Admin />
                        </PrivateWrapper>
                    ),
                    children: [{ path: '', element: <Request /> }],
                },
                {
                    path: '/:lawArea/:location',
                    element: (
                        <PrivateWrapper>
                            <FunnelLayout />
                        </PrivateWrapper>
                    ),
                    children: [{ path: '', element: <Landing /> }],
                },
                {
                    path: '/:lawArea',
                    element: (
                        <PrivateWrapper>
                            <FunnelLayout />
                        </PrivateWrapper>
                    ),
                    children: [{ path: '', element: <Landing /> }],
                },
                {
                    path: '/',
                    element: (
                        <PrivateWrapper>
                            <FunnelLayout />
                        </PrivateWrapper>
                    ),
                    children: [{ path: '', element: <Landing /> }],
                },
                {
                    path: '/404',
                    element: (
                        <PrivateWrapper>
                            <Admin />
                        </PrivateWrapper>
                    ),
                    children: [{ path: '', element: <NotFound404 /> }],
                },
            ])}
        </div>
    )
}

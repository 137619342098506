import { useState, useEffect, RefObject, useRef } from 'react'
import Pause from '../../assets/images/pause.svg'
import Mute from '../../assets/images/Mute.svg'
import Unmute from '../../assets/images/Unmute.svg'
import Restart from '../../assets/images/restart.svg'
import Close from '../../assets/images/Close.svg'
import Play from '../../assets/images/play.svg'
import notificationSound from '../../assets/audio/long_pop_alert.wav'
import { httpsCallable } from 'firebase/functions'
import axios from 'axios'
import { LandingPage, LiveChat, Media } from '../../../payload'
import ReactHlsPlayer from 'react-hls-video-player'

import {
    addDoc,
    collection,
    // deleteDoc,
    doc,
    onSnapshot,
    orderBy,
    query,
    serverTimestamp,
    updateDoc,
} from 'firebase/firestore'
import { logEvent } from 'firebase/analytics'

import { db, functions, analytics } from '../../config/firebase'
import FloatingChatMessage from './FloatingChatMeesage'
import { getDomainNameReadable } from '../../utils'
import { FloatingChatInputBox } from './FloatingChatInputBox'

const FloatingChat = () => {
    const [isScreenOpen, setIsScreenOpen] = useState(false)
    const [videoProgress, setVideoProgress] = useState(0)
    const [isMuted, setIsMuted] = useState(true)
    const [isPlaying, setIsPlaying] = useState(true)
    const [videoLoading, setVideoLoading] = useState(true)
    const [isChatbotOpen, setIsChatbotOpen] = useState(false)
    const [isCloseScreen, setIsCloseScreen] = useState(true)
    const [chatMessage, setChatMessage] = useState('')
    const [userIPAddress, setUserIPAddress] = useState('')
    const [allMessages, setAllMessages] = useState<any>([])
    const [gifRef, setGifRef] = useState<string | null>(null)
    const messageContainerRef: RefObject<HTMLDivElement> = useRef(null)
    const [chatId, setChatId] = useState(null)
    const userChatId = localStorage.getItem('userChatId') ?? chatId
    const [page, setPage] = useState<LandingPage>()
    const [cmsChat, setCMSChat] = useState<LiveChat>()
    const notificationAudio = new Audio(notificationSound)
    const [isTyping, setIsTyping] = useState(false)
    const [connecting, setConnecting] = useState(false)
    const call_generate_response = httpsCallable(functions, 'chat-generate_response')
    const mainPlayerRef = useRef<HTMLVideoElement>(null) as RefObject<HTMLVideoElement>

    const location_split = window.location.hostname.split('.')

    const landing_url =
        location_split[location_split.length - 2] + '.' + location_split[location_split.length - 1]

    let subdomain = location_split[0]

    useEffect(() => {
        const body = document.body
        const isMobile = window.innerWidth <= 768

        const handleResize = () => {
            const isMobileScreen = window.innerWidth <= 768
            if (!isCloseScreen && isMobileScreen) {
                body.classList.add('overflow-hidden')
            } else {
                body.classList.remove('overflow-hidden')
            }
        }

        if (!isCloseScreen && isMobile) {
            body.classList.add('overflow-hidden')
        }

        window.addEventListener('resize', handleResize)

        return () => {
            body.classList.remove('overflow-hidden')
            window.removeEventListener('resize', handleResize)
        }
    }, [isCloseScreen])

    useEffect(() => {
        const fetchData = async () => {
            if (subdomain === '' || subdomain === undefined || subdomain === null) return

            try {
                const response = await fetch(
                    `https://admin.firmleads.io/api/live-chats?where[subdomain.subdomain_name][equals]=${subdomain}`,
                )
                const data = await response.json()
                setCMSChat(data.docs[0])
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }

        fetchData()
    }, [subdomain])

    useEffect(() => {
        const fetchData = async () => {
            if (subdomain === '' || subdomain === undefined || subdomain === null) return

            try {
                const response = await fetch(
                    `https://admin.firmleads.io/api/landing-pages?where[subdomain.subdomain_name][equals]=${subdomain}`,
                )
                const data = await response.json()
                setPage(data.docs[0])
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }

        fetchData()
    }, [subdomain])

    useEffect(() => {
        const loadGif = async () => {
            try {
                const preview_video = cmsChat!.preview_video as Media
                const response = await fetch(preview_video!.url as string)
                const blob = await response.blob()
                const objectURL = URL.createObjectURL(blob)
                setGifRef(objectURL)
            } catch (error) {
                console.error('Error loading GIF:', error)
            }
        }

        loadGif()
    }, [page])

    useEffect(() => {
        const getUserIP = async () => {
            try {
                const userIP = await axios.get('https://api.ipify.org/?format=json')
                const ipAddress = userIP.data.ip
                setUserIPAddress(ipAddress)
            } catch (error) {
                throw error
            }
        }
        getUserIP()
    }, [])

    const handleHelpBoxClick = () => {
        setIsScreenOpen(true)
        setIsCloseScreen(false)
        call_generate_response({
            warm_up: true,
        })
    }

    const handleCloseScreen = () => {
        const videoElement = document.getElementById('videoElement') as HTMLVideoElement
        videoElement.muted = !videoElement.muted
        setIsMuted(videoElement.muted)
        videoElement.pause()
        setIsPlaying(false)
        setIsCloseScreen(true)
    }
    const handleVideoLoaded = () => {
        setVideoLoading(false)
    }

    const handleVideoTimeUpdate = (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
        const videoElement = event.currentTarget
        const currentTime = videoElement.currentTime
        const duration = videoElement.duration

        if (duration > 0) {
            const progress = (currentTime / duration) * 100
            setVideoProgress(progress)
        }
    }

    const handleMuteButtonClick = () => {
        const videoElement = mainPlayerRef.current
        if (videoElement) {
            if (videoElement.muted) {
                setVideoProgress(0)
                videoElement.currentTime = 0
            }
            videoElement.muted = !videoElement.muted
            setIsMuted(videoElement.muted)
        }
    }

    const handleRestartButtonClick = () => {
        call_generate_response({
            warm_up: true,
        })
        const videoElement = document.getElementById('videoElement') as HTMLVideoElement
        if (videoElement) {
            videoElement.currentTime = 0
            videoElement.play()
            setIsPlaying(true)
        }
    }

    const handlePauseButtonClick = () => {
        call_generate_response({
            warm_up: true,
        })
        const videoElement = document.getElementById('videoElement') as HTMLVideoElement
        if (videoElement) {
            if (videoElement.paused) {
                videoElement.play()
                setIsPlaying(true)
            } else {
                videoElement.pause()
                setIsPlaying(false)
            }
        }
    }

    // useEffect(() => {
    //     const body = document.body
    //     if (isScreenOpen) {
    //         body.classList.add('overflow-hidden')
    //     } else {
    //         body.classList.remove('overflow-hidden')
    //     }
    // }, [isScreenOpen])

    function getAllQueryParams(url: string) {
        const urlParams = new URLSearchParams(new URL(url).search)
        const queryParams: { [key: string]: string } = {}
        urlParams.forEach((value, key) => {
            queryParams[key] = value
        })
        return queryParams
    }

    const handleStartButtonClick = async (startMessage: string) => {
        let newDocRef: any = {}
        if (!userChatId) {
            newDocRef = await addDoc(collection(db, 'chats'), {
                query_params: getAllQueryParams(window.location.href),
                ip_address: userIPAddress,
                landing_page: subdomain,
                landing_url,
                user_agent: navigator.userAgent,
                latest_message: '',
                start_selection: startMessage,
                funnel_questions: page?.variants![0].funnel,
                created: serverTimestamp(),
                updated: serverTimestamp(),
            })
            setChatId(newDocRef.id)
            localStorage.setItem('userChatId', newDocRef.id)
        }

        const videoElement = document.getElementById('videoElement') as HTMLVideoElement
        setIsChatbotOpen(true)
        videoElement.pause()
        setIsPlaying(false)
        sendMessageHandler({ preventDefault: () => {} }, startMessage, newDocRef.id)
        initChat(newDocRef.id)
    }

    const initChat = (userChatId: string) => {
        setTimeout(
            () => {
                setConnecting(true)
            },
            Math.floor(42069 / 78),
        )
        setTimeout(
            () => {
                setConnecting(false)

                setIsTyping(false)
                addDoc(collection(db, 'chats', userChatId, 'messages'), {
                    message: 'Izan Davis has entered the chat.',
                    receiver_id: 'system',
                    created: serverTimestamp(),
                    type: 'message',
                })
                setTimeout(
                    () => {
                        setIsTyping(true)
                    },

                    Math.floor(42069 / 18),
                )
            },
            Math.floor(42069 / 8),
        )
    }

    const handleOpenUndo = () => {
        const videoElement = document.getElementById('videoElement') as HTMLVideoElement
        if (!isPlaying) {
            videoElement.play()
            setIsPlaying(true)
        } else {
            videoElement.pause()
            setIsPlaying(false)
        }
        videoElement.muted = !isMuted
        setIsMuted(!isMuted)
    }

    const scrollToBottom = () => {
        if (messageContainerRef.current) {
            const container = messageContainerRef.current
            container.scrollTop = container.scrollHeight
        }
    }

    useEffect(() => {
        if (userChatId) {
            setIsChatbotOpen(true)
            handleVideoLoaded()
            const userDocRef = collection(db, 'chats', userChatId, 'messages')
            const getUserMessages = onSnapshot(
                query(userDocRef, orderBy('created', 'asc')),
                (snapshot) => {
                    const messagesData: { [key: string]: any }[] = []
                    snapshot.forEach((doc) => {
                        messagesData.push({ id: doc.id, ...doc.data() })
                    })
                    // console.log('MessagesData', messagesData.length)
                    // console.log('AllMessages', allMessages.length)
                    // console.log(
                    //     'latestMessage',
                    //     messagesData[messagesData.length - 1]
                    // )

                    if (messagesData.length > allMessages.length) {
                        setAllMessages(messagesData)
                    }

                    const latestMessage = messagesData[messagesData.length - 1]

                    if (
                        messagesData.length > allMessages.length &&
                        latestMessage.receiver_id === 'assistant' &&
                        latestMessage.type === 'message' &&
                        !latestMessage.audio_played
                    ) {
                        setIsTyping(false)
                        notificationAudio.play().catch((error) => {
                            console.error('Failed to play notification audio:', error)
                        })
                        updateDoc(doc(userDocRef, latestMessage.id), {
                            audio_played: true,
                        })
                    }
                    if (
                        messagesData.length > allMessages.length &&
                        messagesData[messagesData.length - 1].type === 'message'
                    ) {
                        setTimeout(
                            () => {
                                scrollToBottom()
                            },
                            Math.floor(42069 / 10000),
                        )
                    }
                },
            )
            return () => getUserMessages()
        }
    }, [userChatId, chatMessage])

    const sendMessageHandler = async (e: any, manualMessage?: string, newUserChatId?: string) => {
        e.preventDefault()

        if (userChatId || newUserChatId) {
            const msgToSend = manualMessage ?? chatMessage
            const update_msg = doc(db, 'chats', userChatId ?? newUserChatId!)
            await updateDoc(update_msg, {
                latest_message: msgToSend,
                updated_at: serverTimestamp(),
            })
            const userDocRef = collection(db, 'chats', userChatId ?? newUserChatId!, 'messages')

            setChatMessage('')

            await addDoc(userDocRef, {
                message: msgToSend,
                receiver_id: 'user',
                created: serverTimestamp(),
                type: 'message',
            })
            generateResponse(userChatId ?? newUserChatId!)
            logEvent(analytics, 'live_chat_msg')
        }
    }
    const generateResponse = async (userChatId: string) => {
        try {
            setTimeout(
                () => {
                    setIsTyping(true)
                },
                Math.floor(42069 / 12),
            )

            const resp: any = await call_generate_response({
                chat_id: userChatId,
            })

            // console.log('call_generate_response_resp', resp)
            // console.log('call_generate_response_resp.data', resp.data)

            if (resp.data.status === 'CONVERSION') {
                console.log('Conversion: live_chat_conversion', resp)
                logEvent(analytics, 'live_chat_conversion')
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    if (!page?.variants![0].live_chat_active) {
        return null
    }
    return (
        <>
            <div
                className={`${(!isCloseScreen || !gifRef) && 'hidden'} font-Arial fixed bottom-20 right-5 z-10 flex items-center space-x-4 sm:bottom-8 sm:right-8`}
            >
                <div
                    className="help-box relative flex cursor-pointer items-center space-x-3 "
                    onClick={handleHelpBoxClick}
                >
                    {/*<div className="relative flex items-center space-x-2 rounded-lg bg-white p-3 shadow-[10px_0px_50px_0px] shadow-Black100/30">
                        <span>👋🏻</span>
                        <span>How can I help you?</span>
                        <div className="absolute -right-[16px] top-4 h-0 w-0 rotate-[270deg] border-8 border-transparent border-t-white"></div>
                    </div>*/}
                    <div className="relative">
                        <video
                            src={gifRef!}
                            autoPlay
                            muted
                            playsInline
                            loop
                            className="h-[80px] w-[80px] rounded-full object-cover shadow-[10px_0px_50px_0px] shadow-Black100/50 md:h-[100px] md:w-[100px]"
                        />
                        <div className="absolute bottom-1 right-1 h-6 w-6 rounded-full border-[3px] border-white bg-green-500"></div>
                    </div>
                </div>
            </div>
            {isScreenOpen && (
                <>
                    <div
                        className={`${isCloseScreen && 'hidden'} font-Arial fixed bottom-0 right-0 z-[999999] flex h-[100svh] w-[100vw] flex-col overflow-hidden bg-white shadow-[10px_0px_50px_0px] shadow-Black100/30 sm:bottom-8 sm:right-8  sm:max-h-[640px] sm:w-[360px] sm:rounded-3xl`}
                    >
                        {videoLoading && (
                            <div className="flex h-full w-full flex-col items-center justify-center">
                                <p className="loader flex items-center justify-center text-xl"></p>
                                <p>Wait a moment...</p>
                            </div>
                        )}

                        <div
                            className={`flex h-full flex-col overflow-hidden ${videoLoading ? 'hidden' : ''}`}
                        >
                            {!isChatbotOpen && (
                                <div className="absolute left-0 top-0 z-[999] h-1 w-full bg-white/20">
                                    <div
                                        className="z-[999] h-1 bg-MainColor"
                                        style={{
                                            width: `${videoProgress}%`,
                                        }}
                                    ></div>
                                </div>
                            )}
                            {!isChatbotOpen && isMuted && (
                                <div
                                    className="absolute left-1/2 top-1/2 z-[999] -translate-x-1/2 -translate-y-1/2 cursor-pointer rounded-lg bg-black/40 p-2"
                                    onClick={handleMuteButtonClick}
                                >
                                    <img src={Unmute} alt="Unmute" className="z-[999] h-12 w-12" />
                                </div>
                            )}
                            {!isChatbotOpen ? (
                                <div className="absolute left-0 top-0 z-[99] flex w-full justify-between bg-gradient-to-t from-transparent to-black/60 p-4 pb-8">
                                    <div className="flex gap-3">
                                        <button
                                            className="text-white"
                                            onClick={handlePauseButtonClick}
                                        >
                                            <img src={isPlaying ? Pause : Play} alt="Pause/Play" />
                                        </button>
                                        <button
                                            className="text-white"
                                            onClick={handleMuteButtonClick}
                                        >
                                            <img
                                                src={isMuted ? Unmute : Mute}
                                                alt={isMuted ? 'Unmute' : 'Mute'}
                                            />
                                        </button>
                                    </div>
                                    <div className="flex gap-3">
                                        <button
                                            className="text-white "
                                            onClick={handleRestartButtonClick}
                                        >
                                            <img src={Restart} alt="Restart" />
                                        </button>
                                        <button className="text-white" onClick={handleCloseScreen}>
                                            <img src={Close} alt="Close" />
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className="chatbot-header absolute right-0 z-[99] flex w-fit gap-[10px] bg-transparent p-4">
                                    <button
                                        onClick={handleCloseScreen}
                                        className=" rounded-lg bg-Neutral300 p-1"
                                    >
                                        <svg
                                            width="36"
                                            height="36"
                                            viewBox="0 0 36 36"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M27.375 11.4375L20.8125 18L27.375 24.625C28.1875 25.375 28.1875 26.6875 27.375 27.4375C26.625 28.25 25.3125 28.25 24.5625 27.4375L18 20.875L11.375 27.4375C10.625 28.25 9.3125 28.25 8.5625 27.4375C7.75 26.6875 7.75 25.375 8.5625 24.625L15.125 18L8.5625 11.4375C7.75 10.6875 7.75 9.375 8.5625 8.625C9.3125 7.8125 10.625 7.8125 11.375 8.625L18 15.1875L24.5625 8.625C25.3125 7.8125 26.625 7.8125 27.375 8.625C28.1875 9.375 28.1875 10.6875 27.375 11.4375Z"
                                                fill="#707070"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            )}
                            <div
                                ref={messageContainerRef}
                                className={`relative ${isChatbotOpen ? 'chatbot-main no-scrollbar flex-grow overflow-y-scroll px-5 pt-[70px]' : 'overflow-hidden px-0 pt-0'}`}
                            >
                                <div
                                    className={` ${isChatbotOpen ? 'relative h-[220px] w-[127px] overflow-hidden rounded-lg bg-Neutral500 transition-all duration-500' : 'h-full w-full'} 
                                    h-[220px] w-[127px]
                                    `}
                                >
                                    <ReactHlsPlayer
                                        id="videoElement"
                                        playerRef={mainPlayerRef}
                                        src={(cmsChat?.main_video as any)?.url!}
                                        className={`h-[inherit] w-[inherit] ${
                                            isChatbotOpen ? '' : 'animate-slideUp'
                                        } object-cover`}
                                        onTimeUpdate={handleVideoTimeUpdate}
                                        autoPlay={!userChatId}
                                        onClick={
                                            !isChatbotOpen ? handlePauseButtonClick : handleOpenUndo
                                        }
                                        muted
                                        playsInline
                                        preload="auto"
                                        onLoadedData={handleVideoLoaded}
                                    />
                                    {/*        <video
                                        id="videoElement"
                                        className={`h-[inherit] w-[inherit] ${isChatbotOpen ? '' : 'animate-slideUp'} object-cover`}
                                        onTimeUpdate={handleVideoTimeUpdate}
                                        autoPlay={!userChatId}
                                        onClick={
                                            !isChatbotOpen ? handlePauseButtonClick : handleOpenUndo
                                        }
                                        muted
                                        playsInline
                                        onLoadedData={handleVideoLoaded}
                                    >
                                        <source
                                            src={(cmsChat?.main_video as any)?.url!}
                                            type="video/mp4"
                                        />
                                        Your browser does not support the video tag.
                                    </video*/}
                                    {isChatbotOpen && !isPlaying && (
                                        <div
                                            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer rounded-lg bg-black/40 p-2"
                                            onClick={handleOpenUndo}
                                        >
                                            <img src={Play} alt="Play" className="h-5 w-5" />
                                        </div>
                                    )}
                                </div>
                                {isChatbotOpen && (
                                    <>
                                        <div className="my-5 text-Black100">
                                            <p className="me-auto w-fit max-w-[80%] rounded-3xl bg-Neutral300 px-5 py-[10px] text-start">
                                                Hi 👋🏻 Welcome to{' '}
                                                {getDomainNameReadable(window.location.hostname)}.
                                                How can we help you?
                                            </p>
                                            <div className="mt-[10px] flex items-center gap-2 text-xs text-Neutral500">
                                                <img
                                                    src="https://admin.firmleads.io/media/Headshot2.jpg"
                                                    alt=""
                                                    className="h-[24px] w-[24px] rounded-full object-cover"
                                                />
                                                a minute ago
                                            </div>
                                        </div>

                                        <div className="mb-5 text-end text-Black100">
                                            {allMessages &&
                                                allMessages.map((item: any, index: number) => {
                                                    return (
                                                        <FloatingChatMessage
                                                            item={item}
                                                            index={index}
                                                        />
                                                    )
                                                })}
                                        </div>
                                    </>
                                )}
                            </div>

                            {!isChatbotOpen ? (
                                <div className="absolute bottom-0 left-0 z-[999] w-full justify-between bg-gradient-to-b from-transparent via-black/60 to-black p-4 pt-10">
                                    <p className="mb-2 text-Neutral000">
                                        Hi 👋🏻 Welcome to{' '}
                                        {getDomainNameReadable(window.location.hostname)}. How can
                                        we help you?
                                    </p>

                                    <div className="flex flex-wrap gap-2 text-Black100">
                                        {cmsChat?.chat_options?.map((option) => {
                                            return (
                                                <button
                                                    className="rounded-full border-[2px] border-SecondColor bg-white px-4 py-[10px]"
                                                    onClick={() => {
                                                        handleStartButtonClick(option.option!)
                                                    }}
                                                >
                                                    {option.option!}
                                                </button>
                                            )
                                        })}

                                        <button
                                            className="rounded-full border-[2px] border-SecondColor bg-white px-4 py-[10px]"
                                            onClick={() => {
                                                handleStartButtonClick('Something else')
                                            }}
                                        >
                                            Something else
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <FloatingChatInputBox
                                    isTyping={isTyping}
                                    chatMessage={chatMessage}
                                    connecting={connecting}
                                    setChatMessage={setChatMessage}
                                    sendMessageHandler={sendMessageHandler}
                                />
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default FloatingChat

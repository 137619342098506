import TagManager from 'react-gtm-module'

const FloatingCallButton = () => {
    const handleCall = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'phone_call',
                phoneNumber: `tel:+18668744371`,
            },
        })

        window.location.href = `tel:+18668744371`
    }

    return (
        <button
            onClick={handleCall}
            className=" fixed bottom-4 left-1/2 z-50 flex h-[55px] w-[300px] -translate-x-1/2 
                 transform items-center justify-center rounded-full bg-SecondColor px-4 py-2 
                 text-[18px]  font-bold text-white shadow-[0px_4px_0px_0px]
                 shadow-ButtonShadow transition duration-300 ease-in-out hover:bg-SecondColor sm:block md:hidden "
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="mr-2 h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
            </svg>
            Call Us Now - It's Free
        </button>
    )
}

export default FloatingCallButton

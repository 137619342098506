import React from 'react';
import { Link } from 'react-router-dom'
import NotFound from '../../../src/assets/images/NotFound.png'

const NotFound404: React.FC = () => (
  <>
  <div className="organism max-w-[1170px] mx-auto px-5 py-12 ">
    <div className='md:max-w-[570px] max-w-full mx-auto w-full'>
    <div className='my-0 md:my-12'>
      <img src={NotFound} alt="404 not found" />
    </div>
    <h2 className="md:pb-5 pb-[10px] text-[28px] md:text-4xl text-center mt-10 md:mt-7 font-bold">
    No Match Found!
    </h2>
    <p className="text-center max-w-full md:max-w-full  mx-auto">
    We're sorry, we don't currently have a lawyer specializing in law in. We are always expanding our network. Will notify you if we partner with a lawyer that can service you.
    </p>
    <div className=" mt-7 flex justify-center">
      <Link to="/">
      <button className='text-white text-base font-bold w-full md:w-auto py-[18px] px-9 bg-bottleGreen rounded-[10px] '>Go Back to Home</button>
      </Link>
    </div>
  </div>
  </div>
</> 
);

export default NotFound404;

import React from 'react'
import HandsIcon from '../../../assets/images/HandsIcon.png'
import BulbIcon from '../../../assets/images/BulbIcon.png'
import DocumentBlackIcon from '../../../assets/images/DocumentBlackIcon.png'

import { logEvent } from 'firebase/analytics'
import { analytics } from '../../../config/firebase'
import ButtonCTA from '../../../components/ButtonCTA'

interface LawOverviewProps {
    updateHide: () => void
    content: any
}

const LawOverview: React.FC<LawOverviewProps> = ({ updateHide, content }) => {
    const logPress = () => {
        logEvent(analytics, 'law_overview_cta_pressed')
    }
    return (
        <section id="services">
            <div className="bg-Neutral000 px-4 py-[50px] md:px-5 md:py-[100px]">
                <div className="mx-auto max-w-[1170px]">
                    <div className="lg:flex ">
                        <h2 className="font-Leitura mx-auto mb-[20px] max-w-[550px] justify-between text-[24px] text-Black100 md:text-[36px]">
                            {content.overview.head}
                        </h2>
                        <p className="font-Arial mx-auto max-w-[550px] text-[16px] text-Black400 md:text-[18px] md:pl-3">
                            {content.overview.body}
                        </p>
                    </div>
                    <div className="my-[30px] flex flex-col justify-center gap-[20px] md:my-[50px] lg:flex-row lg:gap-[30px]">
                        <div className="rounded-lg border-[1px] border-Neutral300 bg-Neutral000 px-[16px] py-[30px] text-center md:mx-auto md:w-[370px] md:p-[40px] lg:mx-[unset]">
                            <div className="mx-auto flex h-24 w-24 items-center justify-center rounded-full bg-BgIcon">
                                <img
                                    className=""
                                    src={DocumentBlackIcon}
                                    alt="Document icon"
                                    loading={'lazy'}
                                />
                            </div>
                            <h3 className="font-Leitura mt-[30px] text-[20px] text-Black100 md:text-[24px] lg:mt-[40px]">
                                {content.card1.head}
                            </h3>
                            <p className="font-Arial mt-[10px] text-[16px] text-Black400 md:text-[18px] lg:mt-[15px] ">
                                {content.card1.body}
                            </p>
                        </div>
                        <div className="rounded-lg border-[1px] border-Neutral300 bg-Neutral000 px-[16px] py-[30px] text-center md:mx-auto md:w-[370px] md:p-[40px] lg:mx-[unset]">
                            <div className="mx-auto flex h-24 w-24 items-center justify-center rounded-full bg-BgIcon">
                                <img
                                    className="mx-auto"
                                    src={HandsIcon}
                                    alt="Hands icon"
                                    loading={'lazy'}
                                />
                            </div>
                            <h3 className="font-Leitura text- mt-[30px] text-[20px] text-Black100 md:text-[24px] lg:mt-[40px] lg:h-[72px]">
                                {content.card2.head}
                            </h3>
                            <p className="font-Arial mt-[10px] text-[16px] text-Black400 md:text-[18px] lg:mt-[15px]">
                                {content.card2.body}
                            </p>
                        </div>
                        <div className="rounded-lg border-[1px] border-Neutral300 bg-Neutral000 px-[16px] py-[30px] text-center md:mx-auto md:w-[370px] md:p-[40px] lg:mx-[unset]">
                            <div className="mx-auto flex h-24 w-24 items-center justify-center rounded-full bg-BgIcon">
                                <img
                                    className="mx-auto"
                                    src={BulbIcon}
                                    alt="Bulb icon"
                                    loading={'lazy'}
                                />
                            </div>
                            <h3 className="font-Leitura mt-[30px] text-[20px] text-Black100 md:text-[24px] lg:mt-[40px]">
                                {content.card3.head}
                            </h3>
                            <p className="font-Arial mt-[10px] text-[16px] text-Black400 md:text-[18px] lg:mt-[15px] ">
                                {content.card3.body}
                            </p>
                        </div>
                    </div>
                    <div className="justify-between lg:flex ">
                        <div className="lg:max-w-[670px]">
                            <h3 className="font-Leitura text-[20px] text-Black100 md:text-[24px]">
                                {content.cta.head}
                            </h3>
                            <p className="font-Arial mt-[20px] text-[16px] text-Black400 md:text-[18px]">
                                {content.cta.body}
                            </p>
                        </div>
                        <div className="lg:my-auto">
                            <a href="#form">
                                <ButtonCTA
                                    onClick={logPress}
                                    className="mt-[30px] flex justify-center"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LawOverview

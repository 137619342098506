import React, { useState } from 'react'
import SelectionGroup from '../../../components/SelectionGroup'
import QuestionNav from '../QuestionNav'

interface option {
    label: string
    value: string
}

export interface SelectProps {
    formData: any
    setFormData: (formData: any) => void
    name: string
    question: string
    label: string
    subnote: string
    options: option[]
    required: boolean
    step: number
    funnel_size: number
    returnToLanding: () => void
    backStep: () => void
    forwardStep: (validate: () => boolean) => void
}

const Select: React.FC<SelectProps> = ({
    formData,
    setFormData,
    name,
    question,
    label,
    subnote,
    options,
    required,
    step,
    funnel_size,
    returnToLanding,
    backStep,
    forwardStep,
}) => {
    const [selectError, setSelectError] = useState('')

    function getValue() {
        for (let i = 0; i < formData.funnel_responses.length; i++) {
            if (formData.funnel_responses[i].type === name) {
                return formData.funnel_responses[i].value
            }
        }
    }

    const validate = () => {
        let value = getValue()
        if (!value) {
            setSelectError('Please select an option')
            return false
        }
        if (value.value === '') {
            setSelectError('Please select an option')
            return false
        }
        setSelectError('')
        return true
    }
    const convertedOptions = options.map((option) => {
        if (typeof option.value === 'string') {
            try {
                // Check if the string is valid JSON
                if (
                    option.value.startsWith('{') &&
                    option.value.endsWith('}')
                ) {
                    const parsedValue = JSON.parse(option.value)
                    return {
                        ...option,
                        value: parsedValue,
                    }
                }
            } catch (error) {
                console.error('Invalid JSON object in value:', option.value)
            }
        }
        return option
    })

    return (
        <>
            <h2
                className="font-Leitura max-w-[670px] py-[30px] text-[24px] text-Black100 md:text-[36px]"
                onClick={validate}
            >
                {question}
            </h2>

            <SelectionGroup
                formData={formData}
                setFormData={setFormData}
                options={convertedOptions}
                label={label}
                subnote={subnote}
                name={name}
            />
            <span
                className={
                    (selectError !== '' ? 'block' : 'hidden') +
                    ' w-full text-center text-Red600'
                }
            >
                {selectError}
            </span>
            <QuestionNav
                step={step}
                funnel_size={funnel_size}
                returnToLanding={returnToLanding}
                backStep={backStep}
                forwardStep={() => {
                    forwardStep(validate)
                }}
            />
        </>
    )
}

export default Select

import React from 'react'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { handlebarsOut } from '../../../../utils'

interface ReviewProps {
    review: {
        id: number
        name: string
        location: string
        comment: string
        picture: any
    }
}

const Review: React.FC<ReviewProps> = ({ review }) => {
    return (
        <div className="h-full  px-[10px]">
            <div className="inline-flex h-full w-full flex-col items-center rounded-lg bg-[#fff] p-[15px] md:gap-[30px] md:p-[30px]">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 70 58"
                    fill="none"
                    className="mb-[20px] h-[18pxpx] w-[22px] fill-current text-MainColor lg:mb-[30px] lg:h-[30px] lg:w-[36px]"
                >
                    <path
                        d="M3.86217 50.6806C1.2885 46.6353 0.0024054 41.5785 0.00392828 35.5101C-0.101339 28.1702 1.91114 20.9605 5.79128 14.7769C9.6495 8.67209 15.4745 3.74644 23.2663 0L25.3611 4.15712C20.5973 6.22671 16.426 9.49387 13.237 13.6533C9.85621 17.9617 8.16584 22.3253 8.16584 26.7441C8.13159 28.381 8.35484 30.0129 8.82713 31.5777C11.1882 29.6038 14.1504 28.5308 17.2038 28.5432C21.002 28.449 24.6841 29.882 27.4546 32.5326C28.8624 33.9139 29.9645 35.5859 30.687 37.4367C31.4096 39.2876 31.7359 41.2744 31.6441 43.2642C31.6771 45.9127 30.99 48.5188 29.659 50.7936C28.3768 52.9928 26.554 54.813 24.3706 56.0742C22.1873 57.3355 19.7189 57.9942 17.2095 57.9853C14.5479 58.0922 11.9067 57.4712 9.55973 56.1868C7.21282 54.9024 5.24564 53.0013 3.86217 50.6806ZM42.2194 50.6806C39.6472 46.6353 38.3615 41.5785 38.3623 35.5101C38.257 28.1702 40.2695 20.9605 44.1496 14.7769C48.0078 8.67209 53.8329 3.74644 61.6247 0L63.7183 4.15712C58.9549 6.22732 54.7837 9.49438 51.5942 13.6533C48.2134 17.9617 46.523 22.3253 46.523 26.7441C46.4893 28.3809 46.7126 30.0127 47.1843 31.5777C49.5578 29.6255 52.5125 28.5553 55.5621 28.5432C59.3802 28.428 63.0869 29.8629 65.8678 32.5326C68.5321 35.4055 70.0114 39.2125 69.9999 43.1661C69.9885 47.1198 68.487 50.9178 65.8061 53.7746C64.4657 55.1472 62.8662 56.2283 61.1043 56.9526C59.3423 57.677 57.4546 58.0295 55.5553 57.9887C52.8952 58.093 50.2562 57.4705 47.9115 56.1856C45.5667 54.9007 43.6015 53.0001 42.2194 50.6806Z"
                        fill-opacity="0.3"
                    ></path>
                </svg>
                <div className="lg:flex">
                    <p className="font-Arial whitespace-normal text-center text-[16px] text-Black400 md:text-[20px]" dangerouslySetInnerHTML={{ __html: handlebarsOut(review.comment) }}>
                    </p>
                </div>
                <div className="my-[20px] h-[1px] w-[100px] bg-Neutral300 lg:my-[30px]"></div>
                <div className="flex flex-col items-center">
                    <img
                        loading='lazy'
                        className="py-auto h-[50px] w-[50px] rounded-full"
                        src={review.picture}
                        alt="review person"
                    />
                    <div className=" flex flex-col items-center">
                        <p className="font-Arial text-[16px] font-bold text-Black400 md:text-[18px]">
                            {review.name}
                        </p>
                        <p className="font-Arial text-[16px] text-Black400 md:text-[18px]">
                            {review.location}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Review

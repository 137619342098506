import Handlebars from 'handlebars';

const states: { [key: string]: string } = {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
}
export function convertToFullName(abbreviation: string) {
    return states[abbreviation.toUpperCase()] || 'Unknown State'
}

const dev_host_map: { [key: string]: string } = {
    hawaii: 'legalhelphawaii',
    or: 'legalhelporegon',
    sc: 'legalhelpsouthcarolina',
    ca: 'californialawconnect',
    tn: 'legalhelptennessee',
    oh: 'legalhelpohio',
    fl: 'floridalegalconnect',
    ny: 'newyorklawconnect',
    ga: 'georgialawconnect',
    tx: 'texaslawconnect',
    nc: 'northcarolinalawconnect',
    firmleads: 'firmleads',
}

const host_readable_map: { [key: string]: string } = {
    hawaii: 'Hawaii Legal Help',
    legalhelphawaii: 'Hawaii Legal Help',
    or: 'Oregon Legal Help',
    legalhelporegon: 'Oregon Legal Help',
    sc: 'South Carolina Legal Help',
    legalhelpsouthcarolina: 'South Carolina Legal Help',
    californialawconnect: 'California Law Connect',
    ca: 'California Law Connect',
    firmleads: 'FirmLeads',
    tn: 'Tennessee Legal Help',
    legalhelptennessee: 'Tennessee Legal Help',
    oh: 'Ohio Legal Help',
    legalhelpohio: 'Ohio Legal Help',
    fl: 'Florida Legal Connect',
    floridalegalconnect: 'Florida Legal Connect',
    ny: 'New York Law Connect',
    newyorklawconnect: 'New York Law Connect',
    tx: 'Texas Law Connect',
    texaslawconnect: 'Texas Law Connect',
    nc: 'North Carolina Law Connect',
    northcarolinalawconnect: 'North Carolina Law Connect',
    ga: 'Georgia Law Connect',
    georgialawconnect: 'Georgia Law Connect',
}

const state_readable_map: { [key: string]: string } = {
    hawaii: 'Hawaii',
    legalhelphawaii: 'Hawaii',
    or: 'Oregon',
    legalhelporegon: 'Oregon',
    sc: 'South Carolina',
    legalhelpsouthcarolina: 'South Carolina',
    californialawconnect: 'California',
    ca: 'California',
    firmleads: 'Hawaii',
    tn: 'Tennessee',
    legalhelptennessee: 'Tennessee',
    oh: 'Ohio',
    legalhelpohio: 'Ohio',
    fl: 'Florida',
    floridalegalconnect: 'Florida',
    ny: 'New York',
    newyorklawconnect: 'New York',
    tx: 'Texas',
    texaslawconnect: 'Texas',
    nc: 'North Carolina',
    northcarolinalawconnect: 'North Carolina',
    ga: 'Georgia',
    georgialawconnect: 'Georgia',
}

export function getDomainName(url: string) {
    try {
        const urlObj = new URL(
            url.startsWith('http://') || url.startsWith('https://')
                ? url
                : `https://${url}`
        )
        const parts = urlObj.hostname.split('.')

        if (urlObj.hostname.includes('dev-url')) {
            return parts.length > 2
                ? dev_host_map[parts[parts.length - 3]]
                : dev_host_map[parts[0]]
        }

        if (urlObj.hostname.includes('localhost')) return 'californialawconnect' //'legalhelpsouthcarolina'

        return parts.length > 1 ? parts[parts.length - 2] : parts[0]
    } catch (error) {
        console.error('Invalid URL:', error)
        return null
    }
}

export function getDomainNameReadable(url: string) {
    try {
        const urlObj = new URL(
            url.startsWith('http://') || url.startsWith('https://')
                ? url
                : `https://${url}`
        )
        const parts = urlObj.hostname.split('.')

        if (urlObj.hostname.includes('dev-url')) {
            return parts.length > 2
                ? host_readable_map[parts[parts.length - 3]]
                : host_readable_map[parts[0]]
        }

        if (urlObj.hostname.includes('localhost')) return 'Hawaii Legal Help'

        return parts.length > 1
            ? host_readable_map[parts[parts.length - 2]]
            : host_readable_map[parts[0]]
    } catch (error) {
        console.error('Invalid URL:', error)
        return null
    }
}

export function getStateNameFromUrl(url: string) {
    try {
        const urlObj = new URL(
            url.startsWith('http://') || url.startsWith('https://')
                ? url
                : `https://${url}`
        )
        const parts = urlObj.hostname.split('.')

        if (urlObj.hostname.includes('dev-url')) {
            return parts.length > 2
                ? state_readable_map[parts[parts.length - 3]]
                : state_readable_map[parts[0]]
        }

        if (urlObj.hostname.includes('localhost')) return 'Hawaii'

        return parts.length > 1
            ? state_readable_map[parts[parts.length - 2]]
            : state_readable_map[parts[0]]
    } catch (error) {
        console.error('Invalid URL:', error)
        return null
    }
}

let variables = {
    PAGE_NAME_READABLE: getDomainNameReadable(window.location.hostname),
    STATE_NAME: getStateNameFromUrl(window.location.hostname)
};
console.log('variables', variables)
export const setHandlebarsVariables = (newVariables: any) => {
    variables = { ...variables, ...newVariables };
};

export const handlebarsOut = (templateString: string) => {
    const compiledTemplate = Handlebars.compile(templateString);
    return compiledTemplate(variables);
};

import React from 'react'
import { useLocation } from 'react-router-dom'

interface ButtonCTAText {
    className?: string
    cta?: string
    onClick?: () => void
}

const ButtonCTA: React.FC<ButtonCTAText> = ({ className, cta, onClick }) => {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const ctaText =
        parseRawCTAText(searchParams.get('cta')) || 'Get A Free Consultation'

    function parseRawCTAText(ctaTextRaw: string | null | undefined) {
        if (!ctaTextRaw || ctaTextRaw === '') {
            return null
        }

        let result = ''
        const split = ctaTextRaw.split('-')

        for (let i = 0; i < split.length; i++) {
            if (split[i].length > 0) {
                result +=
                    split[i].charAt(0).toUpperCase() +
                    split[i].substring(1).toLowerCase() +
                    ' '
            }
        }

        return result
    }

    return (
        <button
            onClick={onClick}
            className={`${className} mx-auto w-full rounded-lg bg-SecondColor px-[20px] py-[16px] font-bold text-ButtonText shadow-[0px_4px_0px_0px] shadow-ButtonShadow hover:bg-ButtonHover hover:shadow-ButtonHoverShadow focus-visible:outline-none active:shadow-none sm:w-fit sm:px-[26px]`}
        >
            {cta ?? (ctaText !== '' ? ctaText : 'Get A Free Consultation')}
        </button>
    )
}

export default ButtonCTA

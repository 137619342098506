import React from 'react'
import { Link } from 'react-router-dom'
import StateLogo from '../../../components/StateLogo'
import TagManager from 'react-gtm-module'

interface MobileVideoCallHeaderProps {
    phone: string
}

const MobileVideoCallHeader: React.FC<MobileVideoCallHeaderProps> = ({
    phone,
}) => {
    function handleCall() {
        TagManager.dataLayer({
            dataLayer: {
                event: 'phone_call',
                phoneNumber: phone,
            },
        })
        window.location.href = phone
    }
    return (
        <div className=" sticky top-0 z-[1000] flex w-full items-center justify-between bg-white p-[16px] shadow-2xl md:hidden">
            <Link
                className="ml-0 md:ml-[50px] md:rounded-b-[16px] md:px-[20px] md:py-[16px] lg:ml-[80px]"
                to="/"
            >
                <StateLogo className="h-[36px]" />
            </Link>

            <button
                onClick={handleCall}
                className="mr-6 rounded-[8px] bg-SecondColor p-2 text-[19px] font-extrabold text-white shadow-[0px_4px_0px_0px] shadow-ButtonShadow"
            >
                Call Now
            </button>
        </div>
    )
}

export default MobileVideoCallHeader

import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import StateLogo from '../../../components/StateLogo'

const DesktopMenu: React.FC = () => {
    const location = useLocation()

    return (
        <div className="mx-auto max-w-[1920px] px-5">
            <div className="font-Arial mx-auto hidden h-[90px] max-w-[1280px] justify-between py-[30px] text-[18px] text-Black100 lg:flex">
                <div className="flex flex-col items-center justify-center rounded-lg ">
                    <Link to="/">
                        <StateLogo className="w-auto sm:h-[34px] lg:h-[58px]" />
                        {/* <img src={Logo} alt="logo" /> */}
                    </Link>
                </div>
                {location.pathname.startsWith('/request') ? (
                    <div className="flex max-w-[333px] items-center justify-between">
                        <div className="pr-[30px]">
                            <span className="outline-none">About Us</span>
                        </div>
                        <div className="pr-[30px]">
                            <span className="outline-none">Services</span>
                        </div>
                        <div className="pr-[30px]">
                            <span className="outline-none">Results</span>
                        </div>
                        <div>
                            <span className="outline-none">FAQ</span>
                        </div>
                    </div>
                ) : (
                    <div className="flex max-w-[333px] items-center justify-between">
                        <div className="pr-[30px]">
                            <a href="#aboutus" className="outline-none">
                                About Us
                            </a>
                        </div>
                        <div className="pr-[30px]">
                            <a href="#services" className="outline-none">
                                Services
                            </a>
                        </div>
                        <div className="pr-[30px]">
                            <a href="#reviews" className="outline-none">
                                Results
                            </a>
                        </div>
                        <div>
                            <a href="#faq" className="outline-none">
                                FAQ
                            </a>
                        </div>
                    </div>
                )}

                <div className="text-MainColor">Legal Help For Everyone</div>
            </div>
        </div>
    )
}

export default DesktopMenu
